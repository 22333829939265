import React, { useRef, useState } from "react";
import Alert from "@material-ui/lab/Alert"
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import { useAuth } from '../../hooks/useAuth'
import { useHistory } from "react-router-dom"
import MuiAlert from '@material-ui/lab/Alert';


const useStyles = makeStyles((theme) => ({
    root: {
      paddingBottom: theme.spacing(3)
    },
    paper: {
        marginTop: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    },
}));

/**
 * Signup component allows users to create a new account.
 *
 * @component
 */
function ResetPassword() {
    const classes = useStyles();
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const { register, errors, handleSubmit } = useForm();
    const { resetPassword } = useAuth()
    const { user } = useAuth()
    const history = useHistory()
    const [sentEmail, setSentEmail] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    const [errorBool, setErrorBool] = useState(false);

    /**
     * Creates an account based on name, email, and password.
     *
     * @function
     * @param {Object} data - Name, Email, Password
     */
    async function onSubmit(data) {
        setErrorBool(false);
        try {
            await resetPassword(data.email)
            setSentEmail(true);
        } catch (err) {
            console.log(err)
           switch (err.code) {
              case 'auth/email-already-in-use':
                // alert(`Email address already in use.`)
                setErrorMsg(`Email address already in use.`);
                setErrorBool(true);
                break;
              case 'auth/invalid-email':
                // alert(`Email address  is invalid.`);
                setErrorMsg(`Email address  is invalid.`);
                setErrorBool(true);
                break;
              case 'auth/operation-not-allowed':
                // alert(`Invalid Operation: Error during sign up. The provided sign-in provider is disabled by Versa.`);
                setErrorMsg(`Invalid Operation: Error during sign up. The provided sign-in provider is disabled by Versa.`);
                setErrorBool(true);
                break;
              case 'auth/weak-password':
                // alert('Password is not strong enough. Add additional characters including special characters and numbers.');
                setErrorMsg('Password is not strong enough. Add additional characters including special characters and numbers.');
                setErrorBool(true);
                break;
            case 'auth/user-not-found':
                // alert('There is no user record corresponding to this identifier. The user may have been deleted.');
                setErrorMsg('There is no user record corresponding to this identifier. The user may have been deleted.');
                setErrorBool(true);
                break;
              default:
                console.log(error.message);
                // alert(error.message)
                setErrorMsg(error.message);
                setErrorBool(true);
                break;
            }
        }
    }

    return (
        <Container component="main" maxWidth="xs" className = {classes.root}>
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Reset Password 
                </Typography>
                {errorBool && <MuiAlert className="mb-4" severity="error">
                    <div className="d-flex align-items-center align-content-center">
                    <span>
                        <strong className="d-block">Error: </strong>{errorMsg}
                    </span>
                    </div>
                </MuiAlert>}
                <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                inputRef={register({ required: true })}
                            />
                            {errors.email && <Alert severity="error">Email Error</Alert>}
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        style={{ backgroundColor: '#6136D8' }}
                    >
                        Reset Password 
                    </Button>
                    {sentEmail && <Alert>Email sent</Alert>}
                </form>
            </div>
        </Container>
    );
}

export default ResetPassword;