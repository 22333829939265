import React, { useRef, useState } from "react";
import Alert from "@material-ui/lab/Alert"
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import { useAuth } from '../../hooks/useAuth'
import { useHistory } from "react-router-dom"
import MuiAlert from '@material-ui/lab/Alert';


const useStyles = makeStyles((theme) => ({
    root: {
      paddingBottom: theme.spacing(3)
    },
    paper: {
        marginTop: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    },
}));

/**
 * Signup component allows users to create a new account.
 *
 * @component
 */
function SignUp() {
    const classes = useStyles();
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const { register, errors, handleSubmit } = useForm();
    const { signUp, signUpWithGoogle } = useAuth()
    const { user } = useAuth()
    const history = useHistory()
    const [errorMsg, setErrorMsg] = useState();
    const [errorBool, setErrorBool] = useState(false);

    /**
     * Creates an account based on name, email, and password.
     *
     * @function
     * @param {Object} data - Name, Email, Password
     */
    async function onSubmit(data) {
        try {
            await signUp(data.firstName, data.lastName, data.email, data.password)
            history.push("/myprofile")
        } catch (err) {
            console.log('inside signup.js catch statement')
            console.log(err)
           switch (err.code) {
              case 'auth/email-already-in-use':
                console.log(`Email address already in use.`);
                // alert(`Email address already in use.`)
                setErrorMsg(`Email address already in use.`);
                setErrorBool(true);
                break;
              case 'auth/invalid-email':
                console.log(`Email address is invalid.`);
                // alert(`Email address  is invalid.`);
                setErrorMsg(`Email address  is invalid.`);
                setErrorBool(true);
                break;
              case 'auth/operation-not-allowed':
                console.log(`Error during sign up.`);
                // alert(`Invalid Operation: Error during sign up. The provided sign-in provider is disabled by Versa.`);
                setErrorMsg(`Invalid Operation: Error during sign up. The provided sign-in provider is disabled by Versa.`);
                setErrorBool(true);
                break;
              case 'auth/weak-password':
                console.log('Password is not strong enough. Add additional characters including special characters and numbers.');
                // alert('Password is not strong enough. Add additional characters including special characters and numbers.');
                setErrorMsg('Password is not strong enough. Add additional characters including special characters and numbers.');
                setErrorBool(true);
                break;
              default:
                console.log(error.message);
                // alert(error.message)
                setErrorMsg(error.message);
                setErrorBool(true);
                break;
            }
        }
    }

    /**
     * Creates an account based on the user's Google account.
     *
     * @function
     */
    async function googleSignup() {
        try {
            await signUpWithGoogle()
            history.push("/myprofile")
        } catch (err) {
            console.log(err)
        }

    }

    return (
        <Container component="main" maxWidth="xs" className = {classes.root}>
            <CssBaseline />
            <div className={classes.paper}>

                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                {errorBool && <MuiAlert className="mb-4" severity="error">
                    <div className="d-flex align-items-center align-content-center">
                    <span>
                        <strong className="d-block">Error: </strong>{errorMsg}
                    </span>
                    </div>
                </MuiAlert>}
                <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="fname"
                                name="firstName"
                                variant="outlined"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                autoFocus
                                inputRef={register({ required: true })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="lname"
                                inputRef={register({ required: true })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                inputRef={register({ required: true })}
                            />
                            {errors.email && <Alert severity="error">This is an error alert — check it out!</Alert>}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                inputRef={register({ required: true })}
                            />
                            {errors.password && <Alert severity="error">This is an error alert — check it out!</Alert>}
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value="allowExtraEmails"
                                        color="primary"
                                    />
                                }
                                label="I want to receive inspiration, marketing promotions and updates via email."
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        style={{ backgroundColor: '#6136D8' }}
                    >
                        Sign Up
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link href="/login" variant="body2">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>

                </form>
                <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    onClick={() => googleSignup()}
                >
                    Sign Up with Google
                </Button>
            </div>
        </Container>
    );
}

export default SignUp;