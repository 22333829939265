import React, { Component } from "react";
import { db } from "../../config/firebase";

import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  TextField,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import Chat from '@material-ui/icons/Chat';
import SendIcon from '@material-ui/icons/Send';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import moment from 'moment';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  currentuser: {
    padding: theme.spacing(1),
    backgroundColor: '#ffffff'
  },
  otheruser: {
    padding: theme.spacing(1),
    backgroundColor: '#f7f7f7',
  },
  avatar: {
    height: 25,
    width: 25,
    margin: 5,
  },
  avatarcolor: {
    color: theme.palette.getContrastText('#6136D8'),
    backgroundColor: '#6136D8',
  },
  divider: {
    width: '1px',
    height: '14px',
    marginLeft: '20px',
    marginRight: '20px',
  }
});

class Chats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: props.currentUser,
      otherUser: props.otherUser,
      chats: [],
      content: '',
      readError: null,
      writeError: null,
      loadingChats: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.myRef = React.createRef();
    console.log("AHHHHHHHHHHHHHHHHH");
    console.log(this.state.currentUser);
    console.log(this.state.otherUser);
  }

  async componentDidMount() {
    console.log(this.state.user);
    this.setState({ readError: null, loadingChats: true });
    const chatArea = this.myRef.current;
    try {
      db.ref("chats").on("value", snapshot => {
        let chats = [];
        snapshot.forEach((snap) => {
          chats.push(snap.val());
        });
        chats.sort(function (a, b) { return a.timestamp - b.timestamp })
        this.setState({ chats });
        chatArea.scrollBy(0, chatArea.scrollHeight);
        this.setState({ loadingChats: false });
      });
    } catch (error) {
      this.setState({ readError: error.message, loadingChats: false });
    }
  }

  async componentDidUpdate() {
    console.log("updated.");
    console.log(this.props.otherUser);
    if (this.state.otherUser != this.props.otherUser) {
      this.setState({
        otherUser: this.props.otherUser,
      });
    }
  }

  handleChange(event) {
    this.setState({
      content: event.target.value
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    console.log(this.state.otherUser);
    console.log("Submit hit!");
    this.setState({ writeError: null });
    const chatArea = this.myRef.current;
    try {
      await db.ref("chats").push({
        content: this.state.content,
        timestamp: Date.now(),
        senduid: this.state.currentUser.id,
        receiveuid: this.state.otherUser.id,
      });
      this.setState({ content: '' });
      chatArea.scrollBy(0, chatArea.scrollHeight);
    } catch (error) {
      this.setState({ writeError: error.message });
    }
  }

  // formatTime(timestamp) {
  //   const d = new Date(timestamp);
  //   const time = `${d.getDate()}/${(d.getMonth()+1)}/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
  //   return time;
  // }

  formatTime(timestamp) {
    const d = new Date(timestamp);
    let formattedMinutes = d.getMinutes().toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })
    const time = `${d.getDate()}/${(d.getMonth()+1)}/${d.getFullYear()} ${d.getHours()}:${formattedMinutes}`;
    return time;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root} ref={this.myRef}>
          <Card className="card-box mb-4">
            {/* <div className="card-header"> */}
            <CardHeader 
              style={{backgroundColor: "#6136D8"}}
              title={
                <div className="text-white font-size-lg d-block text-truncate">
                  {this.props.otherUser.first_name} {this.props.otherUser.last_name}
                  </div>
              }
              avatar={<Avatar 
                  className={classes.avatar}
                  src={this.props.otherUser.profilePicture} 
                  alt={this.state.otherUser.first_name}
                  className={classes.avatarcolor} 
                  />}
              >
              {/* <div className="font-size-lg d-block text-truncate"> */}
                {/* <span className="text-black-50 pr-2">#32</span> */}
                {/* <Avatar className={classes.avatar} src={this.props.otherUser.profilePicture} /> */}
                {/* {this.props.otherUser.first_name} {this.props.otherUser.last_name} */}
              {/* </div> */}
              {/* <div className="ml-auto d-flex align-items-center align-content-center">
                <Tooltip arrow title="View all participants">
                  <IconButton color="primary" className="ml-3">
                    <Chat />
                  </IconButton>
                </Tooltip>
              </div> */}
            {/* </div> */}
            </CardHeader>
            <CardContent className="p-3">
              <form onSubmit={this.handleSubmit} className="mx-3">
                <Grid container spacing={0}>
                  {/* loading indicator */}
                  {this.state.loadingChats && 
                    <Grid item xs={12}>
                      <div className="spinner-border text-success" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </Grid>

                  }
                  {this.state.error && 
                    <Grid item xs={12}><p className="text-danger">{this.state.readError}</p></Grid>
                  }
                  <Grid container item xs={12}>
                    {/* <Paper className={classes.paper} variant="outlined">
                      message here
                    </Paper> */}
                    {/* chat area */}
                    {/* {this.state.chats.filter(chat => chat.senduid == this.state.currentUser.id || chat.receiveuid == this.state.currentUser.id).map(chat => { */}
                    {this.state.chats.filter(chat => (chat.senduid == this.state.currentUser.id && chat.receiveuid == this.state.otherUser.id) || (chat.receiveuid == this.state.currentUser.id && chat.senduid == this.state.otherUser.id)).map(chat => {
                      return (this.props.currentUser.id == chat.senduid ? 
                        <>
                        <Grid item xs={6}></Grid>
                        <Grid container item xs={6}>
                          <Grid item xs={1}>
                            <Avatar className={classes.avatar} src={this.props.currentUser.profilePicture} />
                          </Grid>
                          <Grid item xs={11}>
                            <Paper variant="outlined" key={chat.timestamp} className={classes.currentuser}>
                              {chat.content}
                            </Paper>
                            <span className="chat-time float-right">{moment(chat.timestamp).format('MMMM D, h:mm a')}</span>
                          </Grid>
                          
                        </Grid>
                      </>
                      :
                      <>
                        <Grid container item xs={6}>
                          <Grid item xs={1}>
                            <Avatar className={classes.avatar} src={this.props.otherUser.profilePicture} />
                          </Grid>
                          <Grid item xs={11}>
                            <Paper variant="outlined" key={chat.timestamp} className={classes.otheruser}>
                            {chat.content}
                            </Paper>
                            <span className="chat-time float-right">{moment(chat.timestamp).format('MMMM D, h:mm a')}</span>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}></Grid>
                      </>)
                    })}
                  </Grid>
                  <Grid item xs={12} spacing={1}>
                    {/* <Divider className={classes.divider} flexItem/> */}
                    <hr />
                  </Grid>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={10}>
                      <TextField
                        fullWidth
                        className="m-2"
                        label="Type your message here"
                        id="outlined-size-small"
                        // defaultValue=""
                        variant="outlined"
                        size="small"
                        value={this.state.content}
                        onChange={this.handleChange}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        type="submit"
                        fullWidth
                        className="m-2"
                        variant="contained"
                        color="primary"
                        endIcon={<SendIcon />}
                      >
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>

        {/* <div className="chat-area" ref={this.myRef}>
           loading indicator 
          {this.state.loadingChats ? <div className="spinner-border text-success" role="status">
            <span className="sr-only">Loading...</span>
          </div> : ""}
           chat area 
          {this.state.chats.filter(chat => chat.senduid == this.state.currentUser.id || chat.receiveuid == this.state.currentUser.id).map(chat => {
            return <p key={chat.timestamp} className={"chat-bubble " + (this.props.currentUser.id == chat.senduid ? "current-user" : "other-user")}>
              {chat.content} {this.props.currentUser.id}
              <br />
              <span className="chat-time float-right">{this.formatTime(chat.timestamp)}</span>
            </p>
          })}
        </div>
        <form onSubmit={this.handleSubmit} className="mx-3">
          <textarea className="form-control" name="content" onChange={this.handleChange} value={this.state.content}></textarea>
          {this.state.error ? <p className="text-danger">{this.state.error}</p> : null}
          <button type="submit" className="btn btn-submit px-5 mt-4">Send</button>
        </form>
        <div className="py-5 mx-3">
          Logged in as: <strong className="text-info">{this.state.currentUser.email}</strong>
        </div> */}
      </div>
    );
  }
}

Chats.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Chats);