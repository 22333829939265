import React from 'react'
import './Dashboard.css';
import { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Typography,
    IconButton,
    colors,
    makeStyles
  } from '@material-ui/core';
import Layers from '@material-ui/icons/Layers'
import Bookmark from '@material-ui/icons/Bookmark'
import VisibilityIcon from '@material-ui/icons/Visibility';
import RateReviewIcon from '@material-ui/icons/RateReview';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import avatar1 from '../../assets/images/avatars/avatar1.jpg';
import avatar2 from '../../assets/images/avatars/avatar2.jpg';
import avatar3 from '../../assets/images/avatars/avatar3.jpg';
import MuiAlert from '@material-ui/lab/Alert';
import Widget from '../reusable-components/Widget'
import { useAuth } from "../../hooks/useAuth";
import Moment from 'react-moment';
import { uri } from '../../config/uri';
import axios from 'axios';
import UserHelper from './UserHelper'

  const useStyles = makeStyles((theme) => ({
    root: {
      // height: '100%',
      padding: theme.spacing(3)
    },
    avatar: {
      backgroundColor: colors.red[600],
      height: 65,
      width: 65
    },
    differenceIcon: {
      color: colors.red[900]
    },
    differenceValue: {
      color: colors.red[900],
      marginRight: theme.spacing(1)
    },
    actions: {
        justifyContent: 'flex-end'
      }
  }));
  
// function DashboardPage() {
/**
 * Dashboard component displays the user's most relevant information such as recent bookings and number of active listings.
 *
 * @component
 */
const DashboardPage = ({ className, ...rest }) => {
    // const history = useHistory();
    const { user, logout } = useAuth();
    const classes = useStyles();

    const [numListings, setNumListings] = useState();
    const [numReviews, setNumReviews] = useState();
    const [bookings, setBookings] = useState([]);
    const [bookings_as_seller, setBookingsAsSeller] = useState([]);
    const [numBookings, setNumBookings] = useState(-1);
    const [numBookings_as_seller, setNumBookingsAsSeller] = useState(-1);
    const [convertedBookings, setConvertedBookings] = useState([]);
    const [convertedSellerBookings, setConvertedSellerBookings] = useState([]);

  useEffect(() => {

    const fetchNumListings = async () => {
      const res = await axios.get(`${uri}/num-listings/`, { params: { user_id: user.id }})
      setNumListings(res.data);
    }
    fetchNumListings();

    const fetchNumReviews = async () => {
      const res = await axios.get(`${uri}/num-reviews/`, { params: { user_receive_id: user.id }})
      setNumReviews(res.data);
    }
    fetchNumReviews();

    const convertToBooking = (b) => {
      const booking = {
          listingid: b.metadata.listing_id,
          userid: b.metadata.user_id,
          timestart: b.metadata.chosen_time_slot.split("-")[0],
          timeend: b.metadata.chosen_time_slot.split("-")[1],
          stripeid: b.payment_intent,
          price: (parseInt(b.metadata.listing_amount)/100).toString(),
          bookingstatus: b.payment_status,
          sellerid: b.metadata.seller_id
      };
      console.log(booking);
      return booking;
    }

    const fetch_bookings_as_customer = async() => {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/sessions/${user.id}`);
      const data = await response.data;
      console.log(data);
      setBookings([...data]);
      setNumBookings(data.length);
      setConvertedBookings(data.map((booking) => convertToBooking(booking)));
      return data;
  }
  fetch_bookings_as_customer();

  const fetch_bookings_as_seller = async() => {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/sessions/`, { params: { sellerId: user.id }})
      const data = await response.data;
      console.log(data);
      setNumBookingsAsSeller(data.length);
      setBookingsAsSeller([...data]);
      setConvertedSellerBookings(data.map((booking) => convertToBooking(booking)));
      return data;
  }
  fetch_bookings_as_seller();

  }, []);

    return (
        <div className = {classes.root}>
      <h3>Welcome, {user.first_name}!</h3>
      {/* <MuiAlert className="mb-4" severity="success">
        <div className="d-flex align-items-center align-content-center">
          <span>
            <strong className="d-block">Success!</strong> Your listing Entire Photo Studio has been approved!
          </span>
        </div>
      </MuiAlert> */}
      <Grid container spacing={3}>
        <Widget stat={numListings} description='Active Listings' cardColor='bg-gradient-purple' iconColor='#6136D8' icon={Layers} />
        {numBookings_as_seller > -1 && <Widget stat={numBookings_as_seller} description='Total Customer Bookings' cardColor='bg-gradient-blue' iconColor='#0072c9' icon={VisibilityIcon} />}
        <Widget stat={numReviews} description='Reviews' cardColor='bg-gradient-red' iconColor='#C83E4D' icon={RateReviewIcon} />
        {numBookings > -1 && <Widget stat={numBookings} description='Personal Bookings' cardColor='bg-premium-dark' iconColor='black' icon={Bookmark} />}
      </Grid>
      <Card className="card-box mb-4">
        <div className="card-header">
          <div className="card-header--title">
            <b>Latest Customer Bookings</b>
          </div>
        </div>
        <CardContent className="p-0">
          <div className="table-responsive">
            <Table className="table table-striped table-hover text-nowrap mb-0">
              <TableHead className="thead-light">
                <TableRow>
                  <th style={{ width: '40%' }}>Customer</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Date</th>
                  <th className="text-center">Price</th>
                </TableRow>
              </TableHead>
              <TableBody>
              {convertedSellerBookings.map((booking, index) => (
                <TableRow hover key={index}>
                  <TableCell>
                    <div className="d-flex align-items-center">
                      <Avatar alt="..." src={booking.avatar2} className="mr-2" />
                      <div>
                          <UserHelper userid={booking.userid} />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell className="text-center">
                    {booking.bookingstatus && booking.bookingstatus == 'paid' && 
                    <p className="h-auto py-0 px-3 badge badge-success" style={{ lineHeight: 1.5 }}>
                      {booking.bookingstatus}
                    </p>}
                  {booking.bookingstatus && booking.bookingstatus != 'paid' && 
                    <strong className="h-auto py-0 px-3 badge badge-warning" style={{ lineHeight: 1.5 }}>
                      {booking.bookingstatus}
                    </strong>}
                  </TableCell>
                  <TableCell className="text-center">
                    <div className="h-auto py-0 px-3">
                      {/* {booking.timestart} - {booking.timeend} */}
                    <Moment format="MM/DD/YYYY HH:SS A">{booking.timestart}</Moment> - <Moment format="HH:SS A">{booking.timeend}</Moment>
                    </div>
                  </TableCell>
                  <TableCell className="text-center">
                    <div className="h-auto py-0 px-3">
                      <strong>${booking.price}</strong>
                    </div>
                  </TableCell>
                </TableRow>
                ))}
                </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
      <br />
      <Card className="card-box mb-4">
        <div className="card-header">
          <div className="card-header--title">
            {/* <small>Tables</small> */}
            <b>Latest Personal Bookings</b>
          </div>
        </div>
        <CardContent className="p-0">
          <div className="table-responsive">
            <Table className="table table-striped table-hover text-nowrap mb-0">
              <TableHead className="thead-light">
                <TableRow>
                  <th style={{ width: '40%' }}>Seller</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Date</th>
                  <th className="text-center">Price</th>
                </TableRow>
              </TableHead>
              <TableBody>
              {convertedBookings.map((booking, index) => (
                <TableRow hover key={index}>
                  <TableCell>
                    <div className="d-flex align-items-center">
                      <Avatar alt="..." src={booking.avatar2} className="mr-2" />
                      <div>
                        <UserHelper userid={booking.sellerid} />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell className="text-center">
                    {booking.bookingstatus && booking.bookingstatus == 'paid' && 
                    <p className="h-auto py-0 px-3 badge badge-success" style={{ lineHeight: 1.5 }}>
                      {booking.bookingstatus}
                    </p>}
                  {booking.bookingstatus && booking.bookingstatus != 'paid' && 
                    <p className="h-auto py-0 px-3 badge badge-warning" style={{ lineHeight: 1.5 }}>
                      {booking.bookingstatus}
                    </p>}
                  </TableCell>
                  <TableCell className="text-center">
                    <div className="h-auto py-0 px-3">
                      {/* {booking.timestart} - {booking.timeend} */}
                    <Moment format="MM/DD/YYYY HH:SS A">{booking.timestart}</Moment> - <Moment format="HH:SS A">{booking.timeend}</Moment>
                    </div>
                  </TableCell>
                  <TableCell className="text-center">
                    <div className="h-auto py-0 px-3">
                      <strong>${booking.price}</strong>
                    </div>
                  </TableCell>
                </TableRow>
                ))}
                </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
        </div>
    )
}

export default DashboardPage
