import React from 'react'
import { Avatar, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3)
    },
    avatar: {
        height: 150,
        width: 150
      }
}));
/**
 * About page displays information about the company
 *
 * @component
 */
function AboutPage() {
    const classes = useStyles();
    // const history = useHistory();

    return (
        <Container component="main" maxWidth="md" className = {classes.root}>
            <h3>About Versa</h3>
            <hr />
            <Grid container spacing={3} justify="center">
                <Grid item xs={12}>
                    <h2><i>"A dynamic marketplace created for creators to connect with the people that need their services the most."</i></h2>
                </Grid>
                <Grid item xs={3}>
                    <Avatar className={classes.avatar} src={'https://images.unsplash.com/photo-1580893246395-52aead8960dc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3334&q=80'} />
                </Grid>
                <Grid item xs={3}>
                    <Avatar className={classes.avatar} src={'https://images.unsplash.com/photo-1488926756850-a13b25e2f415?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1647&q=80'} />
                </Grid>
                <Grid item xs={3}>
                    <Avatar className={classes.avatar} src={'https://images.unsplash.com/photo-1614006659838-d4ca51cbd117?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80'} />
                </Grid>
                <Grid item xs={3}>
                    <Avatar className={classes.avatar} src={'https://images.unsplash.com/photo-1598887142487-3c854d51eabb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80'} />
                </Grid>
                <Grid item xs={12}>
                    <p>Starting in Philadelphia, a city that thrives on its creative contributions to the world, we see first hand how important creative services are to the economy. It's the street photographer, but also the wedding photographer, the model who takes your brand to the next level, the stylist who put it together, and the makeup artist who perfected the look.</p>
                    <p>Creative services are needed for many reasons, so we believe not only should you be able to market your work, you should get paid for it too. With Versa we're building a way for your services to get booked seamlessly, where your potential customers can see when you're available, know your pricing, & know the number of services you may provide!</p>
                    <p>For sellers this means never having to worry about going back & forth to see whether or not you're in a clients budget, they'll know right away.</p>
                    <p>For customers, this means you no longer need to pore through Instagram, Craigslist, or rely on word-of-mouth to discover exactly what you need.</p>
                </Grid>
            </Grid>
            <hr />
        </Container>
    )
}

export default AboutPage
