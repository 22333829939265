import React from 'react'
import { Avatar, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import danjacob from "./danjacob.jpg";

const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3)
    },
    avatar: {
        height: 150,
        width: 150
      }
}));
/**
 * Contact page displays information about contacting the company
 *
 * @component
 */
function ContactPage() {
    const classes = useStyles();
    // const history = useHistory();

    return (
        <Container component="main" maxWidth="md" className = {classes.root}>
            <h3>Contact</h3>
            <hr />
            <Grid container spacing={3}>
                <Grid item xs={3} align="center">
                    <Avatar className={classes.avatar} src={danjacob} />
                </Grid>
                <Grid item xs={9}>
                    <p><b>Dan Jacob</b></p>
                    <p>CEO</p>
                    <p>A: 3675 Market Street Philadelphia, PA 19104</p>
                    <p>P: (215) 000 - 0000</p>
                    <p>E: info@versaup.com</p>
                </Grid>
            </Grid>
            <hr />
        </Container>
    )
}

export default ContactPage
