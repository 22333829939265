import React from 'react';
import './Card.css'

/**
 * CardBlack component displays a featured listing on a black background
 *
 * @component
 */
function CardBlack({src, title, description, price}) {
    return (
        <div className='card_black'>
            <img src = {src} alt=''></img>
            <div className = 'card_black_info'>
            <h2 className='card_info_text'>{title}</h2>
            <h4 className='card_info_text'>{description}</h4>
            <h3 className='card_info_text'>{price}</h3>
            </div>
            
        </div>
    )
}

export default CardBlack
