import React, { Component } from 'react'
import SearchResult from '../searchResult/SearchResult'

// /**
//  * Component comment
//  *
//  * @component
//  */
class ListingsPage extends Component {
    state = {
        listings : []
    }
    /**
     * Method comment
     *
     * @function
     */
    componentDidMount() {
        fetch('http://0.0.0.0:8023/listings')
            .then(res => res.json())
            .then((data) => {
                this.setState({ listings: data })
            })
            .catch(console.log) 
    }
    render() {
        return (
            <div>
                {this.state.listings.map((listing) => (
                    <SearchResult 
                        img={listing.pic_url}
                        title={listing.title}
                        description={listing.description}
                        price={listing.price}
                        id={listing.id}
                    />
                ))}
              </div>
        )
    }
}

export default ListingsPage