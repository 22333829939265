import React from 'react'
import './Footer.css'
import { Container, Grid } from '@material-ui/core';
/**
 * Footer component displays copyright data and links to static pages.
 *
 * @component
 */
function Footer() {
    return (
        <div className="footer">
            <Container component="main" maxWidth="lg">
                <Grid container justify="center">
                    <Grid item xs={4}>
                        <p><b>Versa</b></p>
                        <p><a href="/about">About</a></p>
                        <p><a href="/contact">Contact</a></p>
                        <p><a href="/blog">Blog</a></p>
                    </Grid>
                    <Grid item xs={4}>
                        <p><b>Legal</b></p>
                        <p><a href="/termsconditions">Terms & Conditions</a></p>
                        <p><a href="/privacy">Privacy Policy</a></p>
                        <p><a href="/help">Help & FAQs</a></p>
                    </Grid>
                    <Grid item xs={4}>
                        <p><b>Sell on Versa</b></p>
                        <p><a href="/listyourservice">List Your Service</a></p>
                        <p><a href="/makemoneyonversa">Make Money on Versa</a></p>
                        <p><a href="/howitworks">How It Works</a></p>
                    </Grid>
                    <Grid item xs={12}>
                        <hr /><p>&copy; 2021 Versa | All Rights Reserved</p>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Footer
