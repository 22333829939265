import React from 'react'
import { Container } from '@material-ui/core';
import SingleBooking from '../reusable-components/SingleBooking'
import { makeStyles } from '@material-ui/core';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import { useAuth } from "../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3)
    },
}));
/**
 * Booking Confirmation page tells the customer their booking is confirmed and displays the booking details.
 *
 * @component
 */
function BookingConfirmationPage() {
    const classes = useStyles();
    // const history = useHistory();
    const { user, logout } = useAuth();

    const booking = {
        listingid: '',
        userid: '',
        timestart: '02/02/2020 1:00PM',
        timeend: '02/02/2020 3:00PM',
        stripeid: '02/02/2020',
        price: 150,
        bookingstatus: 'complete'
    };

    return (
        <Container component="main" maxWidth="md" className = {classes.root}>
            <h3><CheckCircleOutline className='largeIcon'/> Thanks, {user.first_name}!</h3>
            <hr></hr>
            <p>Your booking has been confirmed.</p>
            <SingleBooking booking={booking} />
            <br />
        </Container>
    )
}

export default BookingConfirmationPage
