import React, {useState, useEffect } from 'react'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {DateRangePicker} from "react-date-range";
import Search from '../search/Search'
import { useForm, Controller } from "react-hook-form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Slider from '@material-ui/core/Slider';
import Moment from 'react-moment';
import { useHistory } from "react-router-dom"
import SearchIcon from '@material-ui/icons/Search';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import axios from 'axios';
import { uri } from '../../config/uri';
import PlacesAutocomplete from './PlacesAutoComplete';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
      margin: theme.spacing(1, 0, 2),
  },
}));

/**
 * SearchBar component displays a form for users to input search criteria.
 *
 * @component
 */
function SearchBar() {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
    // /**
    //  * Method comment
    //  *
    //  * @function
    //  * @param {Object} data - Search Criteria
    //  */
    // function search(data) {
    //     console.log(data)
    // }

    const [open1, setOpen1] = useState(false);
    /**
     * Closes date selection window.
     *
     * @function
     */
    const handleClose1 = () => {
      setOpen1(false);
    };
    /**
     * Opens date selection window.
     *
     * @function
     */
    const handleClickOpen1 = () => {
      setOpen1(true);
    };

    const [showSearch, setShowSearch] = useState(false);
    const [startDate,setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    
    // needed for DateRangePicker
    const SelectionRange = {startDate: startDate, 
        endDate: endDate, key: "selection"};

    /**
     * Updates the StartDate and EndDate values.
     *
     * @function
     * @param {Object} ranges - Selected data range.
     */
    function handleSelect(ranges){
        setStartDate(ranges.selection.startDate);
        setEndDate(ranges.selection.endDate);
    }
    const { register, errors, handleSubmit, control } = useForm();

    const history = useHistory();
    /**
     * Searches listings based on given criteria and redirects to the Search page.
     *
     * @function
     * @param {Object} data - Search Criteria
     */
    function onSubmit(data) {
      history.push({
          pathname: '/search',
          state: {
            searchText: data.searchInput,
            protype: data.protype,
            price: price
          }
        });
  }

  const [price, setPrice] = useState([0, 250]);

  /**
   * Updates price limits.
   *
   * @function
   * @param {Event} event - On value change
   * @param {string} newValue - New price limits
   */
  const handlePriceChange = (event, newPrice) => {
    setPrice(newPrice);
  };

  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 500,
      label: '500',
    },
  ]

  useEffect(() => {
    const fetchCategories = async () => {
      const res = await axios.get(`${uri}/categories/`)
      setCategories(res.data);
    }
    fetchCategories();
  }, []);

    return (
      <Container component="main" maxWidth="md">
            <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                      name="searchInput"
                      variant="outlined"
                      fullWidth
                      id="searchInput"
                      label="Search"
                      autoFocus
                      inputRef={register}
                      size="small"
                  />
                </Grid>
                <Grid item container xs={12} sm={12} spacing={2}>
                  <Grid item xs={3} sm={3}>
                    <Typography id="range-slider" gutterBottom>
                      Pro Type
                    </Typography>
                    <FormControl variant="outlined" className={classes.formControl} size="small" style={{ width: '90%' }}>
                        <Controller
                          control={control}
                          name="protype"
                          defaultValue={-1}
                          as={
                            <Select id="protype-select">
                              {categories.map(category => (
                                <MenuItem key={category.value} value={category.value}>{category.label}</MenuItem>
                              ))}
                              <MenuItem key={-1} value={-1}>Any</MenuItem>
                            </Select>
                          }
                        />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Typography id="range-slider" gutterBottom>Price</Typography>
                    <Slider
                      value={price}
                      onChange={handlePriceChange}
                      valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      step={5}
                      min={0}
                      max={500}
                      marks={marks}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    {/* <PlacesAutocomplete /> */}
                  </Grid>
                  {/* <Grid item xs={3} sm={3}>
                    <Typography gutterBottom>Date</Typography>
                    <Box onClick={handleClickOpen1} borderRadius={10} border={1} borderColor="#c0c0c0" m={1} p={1}>
                      <Moment format="MMM DD">{startDate}</Moment> - <Moment format="MMM DD">{endDate}</Moment>
                    </Box>
                    <Dialog open={open1} onClose={handleClose1} aria-labelledby="form-dialog-title">
                      <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          choose a date
                        </DialogContentText>
                        <DateRangePicker ranges={[SelectionRange]} onChange={handleSelect}/>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose1} color="primary">
                          Cancel
                        </Button>
                        <Button onClick={handleClose1} color="primary">
                          Select
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid> */}
                  <Grid item xs={3} sm={3}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        style={{ width: '90%' }}
                    >
                            <SearchIcon /> Search</Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
        </Container>
    )
}
export default SearchBar;
