import React from "react";

import Button from "@material-ui/core/Button";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios"

// /**
//  * Component comment
//  *
//  * @component
//  */
function Profile() {
    const { user, logout } = useAuth();

    async function logOut() {
        await logout()
    }

    return (
        <>
            {user && (
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        logOut();
                    }}
                >
                    Logout
                </Button>
            )}
            <div>First Name: {user.first_name}</div>
            <div>Last Name: {user.last_name}</div>
            <div>Email: {user.email}</div>
            <div>Authenticated!!</div>
        </>
    );
}
export default Profile;