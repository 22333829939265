import React from "react";
import "./App.css";
import './assets/base.scss';
import "antd/dist/antd.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { useAuth } from "./hooks/useAuth";
import Routes from './Routes'

import { ThemeProvider } from '@material-ui/styles';
import MuiTheme from './theme';

// Material UI
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Header from "./components/header/Header";
import SideMenu from './components/sidemenu/SideMenu'
import Footer from "./components/footer/Footer";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "white",
    color: "black",
    boxShadow: "0px 2px 0px 0px #ECECEC",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(0)
  },
  main: {
    padding: theme.spacing(0)
  },
  footer: {
    position:"relative",
    // padding: theme.spacing(3, 2),
    // padding: theme.spacing(0),
    marginTop: 'auto',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    bottom: 0,
    width:"100%"
  },
}));

/**
 * Component comment
 *
 * @component
 */
function App() {
    const classes = useStyles();
    const { user, logout } = useAuth();
    return (
        <div className={classes.root}>
            <ThemeProvider theme={MuiTheme}>
            <CssBaseline />
            <AuthProvider>
              <Router>
                <AppBar position="fixed" className={classes.appBar}>
                    <Header />
                </AppBar>
                <SideMenu />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div className={classes.main}>
                        <Routes />
                    </div>
                    <footer className={classes.footer}>
                        <Footer />
                    </footer>
                </main>
              </Router>
            </AuthProvider>
            </ThemeProvider>
        </div>
    );
}

export default App;
