import React from 'react';
import './Home.css';
import Banner from '../banner/Banner'
import Card from '../card/Card'
import CardBlack from '../card/CardBlack';
import SearchBar from '../reusable-components/SearchBar'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
// ES7 snippets to do 'rfce'

/**
 * Home component displays the search bar and featured listings/sellers.
 *
 * @component
 */
function Home() {
    return (
        // <Container maxWidth={false} style={{paddingLeft: '0px', paddingRight: '0px'}}>
        <Container maxWidth={false} style={{paddingLeft: '0px', paddingRight: '0px'}}>
        <div className='home'>
            {/* <div style={{backgroundColor: '#6136D8', height: '25px'}} /> */}
            <SearchBar />
            <br />
            <Banner/>

            <div className='card_area_info'>
                <Card
                    src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1581949450947x136150963109774600%2Fpexels-photo-3089849.jpeg"
                    title="Find Your Vibe"
                    description="Choose from hundreds of pros by browsing their portfolios. Find YOUR vibe! "
                />
                <Card
                    src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1593543877429x887659880284579200%2FVersa%2520homepage%2520photoshoot.jpg"
                    title="Schedule & Pay Online"
                    description="Skip the cash! Schedule your appointment and pay online."
                />
                <Card
                    src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1581949450947x136150963109774600%2Fpexels-photo-3089849.jpeg"
                    title="Manage your Bookings"
                    description="Want to find that bomb hairdresser you booked last september? Find him in an instant! Not so sure? Cancel with a click. "
                />
            </div>

            <div className="top_nearby">
                <h1>Explore Top Pros Nearby</h1>
                <h4>Need a headstart? Check out these top pros!</h4>
                <div className='card_area_nearby'>
                <Grid container spacing={0}>
                  <Grid item xs={4}>
                    <CardBlack
                            src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1596134096124x699387632336524700%2F9B38DE30-ABB6-4101-A573-C13323D90A06.jpeg"
                            title="Headshot Photography"
                            description="Get 20+ retouched images in 1-2 looks per hour. Available in-studio or 5mi within Philly."
                            price="$100/hr"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CardBlack
                            src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1585063905511x428027516133678600%2Fd87cfab0-df84-45b8-82b2-dd0fbc278e81_rw_600.png"
                            title="Gina's Freelance Styling"
                            description="Get custom styling decisions with tailored content for your business. Currently work out of Bus Stop Boutique."
                            price="$20/hr"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CardBlack
                            src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1595018434895x670071752497130500%2Fnick4.jpg"
                            title="Portrait/Fashion Photography"
                            description="Showcase the model's unique personality! 1 hour session = 2 outfits upto 2 different locations, with 15 edited shots"
                            price="$80/hr"
                    />
                  </Grid>
                </Grid>
                </div>
            </div>

        </div>
        </Container>
    )
}

export default Home
