import React from 'react';
import './Messages.css';
import axios from 'axios';
// import SearchIcon from '@material-ui/icons/Search';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
// import {Avatar} from '@material-ui/core';
// import {Link} from 'react-router-dom'
// import { Button } from '@material-ui/core';
import {useHistory, location, useLocation} from 'react-router-dom';
// import Container from '@material-ui/core/Container';
// import Grid from '@material-ui/core/Grid';
import { makeStyles, Container } from '@material-ui/core';
import Chat from '@material-ui/icons/Chat';
import Chats from "./Chats";
import { useAuth } from "../../hooks/useAuth";
import { useState, useEffect } from 'react';
import { uri } from '../../config/uri';

const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3)
    },
}));

/**
 * Messages component allows the user to converse with customers via message threads.
 *
 * @component
 */
function MessagesPage(props) {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { user, logout } = useAuth();
    const [ otherUser, setOtherUser ] = useState();
    console.log(props.match.params.otheruserid);
    var otherUserId = props.match.params.otheruserid;
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        console.log("MESSAGES");
        console.log(user);
        // const fetchOtherUser = async() => {
        //     console.log("Fetching other user details")
        //     const res = await axios.get(`${uri}/user/`, { params: { user_id: otherUserId }});
        //     console.log(res);
        //     setOtherUser(res.data);
        // }
        // fetchOtherUser();
        console.log("Fetching other user details");
        axios.get(`${uri}/user/`, { params: { user_id: otherUserId }}).then(res => {
            setOtherUser(res.data);
            setLoading(false);
          });
    }, []);

    /**
     * Sends a message to a specified user.
     *
     * @function
     * @param {Object} data - User IDs, message text
     */
    function sendMessage(data) {
        console.log(data)
    }
    /**
     * Deletes a conversation based on user ID.
     *
     * @function
     * @param {Object} data - User ID
     */
    function deleteConversation(data) {
        console.log(data)
    }

    if (isLoading) {
        return <div>Loading...</div>;
      }

    return (
        <Container maxWidth="md" className={classes.root}>
            <h3><Chat className='largeIcon'/> Messages</h3>
            <hr></hr>
            <Chats currentUser={user} otherUser={otherUser}></Chats>
        </Container>
    )
}

export default MessagesPage
