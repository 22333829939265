import React from 'react'
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3)
    },
}));
/**
 * Help & FAQs page displays information the company's help page
 *
 * @component
 */
function HelpAndFAQsPage() {
    const classes = useStyles();
    // const history = useHistory();

    return (
        <Container component="main" maxWidth="md" className = {classes.root}>
            <h3>Help & FAQs</h3>
            <hr />
            <h6>Help</h6>
            <p>Coming soon...</p>
            <h6>FAQs</h6>
            <p>Coming soon...</p>
            <hr />
        </Container>
    )
}

export default HelpAndFAQsPage
