import React from 'react';
import { useState } from 'react';
import { Button, TextField, InputAdornment } from '@material-ui/core';
import {useHistory} from 'react-router-dom'
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
// // import {Avatar} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import {Link} from 'react-router-dom'
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useAuth } from "../../hooks/useAuth";
import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  // inputRoot: {
  //   color: 'inherit',
  // },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  header: {
    backgroundColor: "transparent",
    color: "black",
    boxShadow: "0px 2px 0px 0px #ECECEC",
  },
  logo: {
    maxWidth: 50,
    marginRight: 5
  },
  margin: {
    margin: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: 200
  },
  btn1: {
    margin: '10px',
    '&:hover': {
      background: "#4b29a6",
      color: 'white',
    },
  },
  btn2: {
    margin: '10px',
    '&:hover': {
      background: "gray",
      color: 'white',
    },
  }
}));

/**
 * Header component contains the Versa logo, login/signup buttons, and account menu.
 *
 * @component
 */
function Header(props) {
    const history = useHistory();
    const classes = useStyles();
    const { user, logout } = useAuth();
    const { register, errors, handleSubmit } = useForm();

    /**
     * Logs out the current user.
     *
     * @function
     */
    async function logOut() {
      handleMenuClose();
      await logout()
    }

    /**
     * Executes a search based on given criteria.
     *
     * @function
     * @param {Object} data - Search criteria.
     */
    function onSubmit(data) {
      history.push({
        pathname: '/search',
        state: {
          searchText: data.search,
          protype: -1,
          price: [0, 500]
        }
      });
      
    }

    // My Account menu
    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);

    /**
     * Opens the dropdown account menu.
     *
     * @function
     * @param {Event} event - When the account button is clicked.
     */
    const handleProfileMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
    /**
     * Closes the dropdown account menu.
     *
     * @function
     */
    const handleMenuClose = () => {
      setAnchorEl(null);
    };
  
    const menuId = 'primary-search-account-menu';
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem component={Link} to="/dashboard" onClick={handleMenuClose}>Dashboard</MenuItem>
        <MenuItem component={Link} to="/myprofile" onClick={handleMenuClose}>My Profile</MenuItem>
        <MenuItem onClick={logOut}>Logout</MenuItem>
      </Menu>
    );

    return (
        <Toolbar>
            <img className = 'header_icon'
                src ='https://dd7tel2830j4w.cloudfront.net/f1584239788542x305794732496912830/27x40%20%281%29.svg' 
                alt="logo" className={classes.logo}
                 />
            
            <Link to="/">
                <Typography className={classes.title} variant="h6" noWrap>
                    Versa
                </Typography>
            </Link>
            <div className={classes.grow} />
            <div className={classes.search}>
              <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="search"
                  placeholder="Search listings..."
                  name="search"
                  size="small"
                  inputRef={register({ required: true })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </div>
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                {!user && (
                  <>
                  <Button 
                      href="/login"
                      value="outlined" 
                      variant="contained" 
                      color="primary" 
                      className={classes.btn1}
                      >Login</Button>
                  <Button 
                      href="/signup"
                      variant="contained" 
                      className={classes.btn2}
                      >Sign Up</Button>
                    </>
                )}
                {user && (
                <Button
                    // variant="outlined"
                    startIcon={<AccountCircleIcon/>}
                    endIcon={<ExpandMoreIcon/>}
                    style={{margin: '10px'}}
                    onClick={handleProfileMenuOpen}
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    >
                      {user.first_name}
                    </Button>
                    )}
              </div>
              {renderMenu}
        </Toolbar>
    )

}


Header.propTypes = {
  /**
   * userLoggedIn is a boolean
   */
  userLoggedIn: PropTypes.bool,
}

export default Header;
