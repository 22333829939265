import React from 'react'
import Paper from '@material-ui/core/Paper';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Container,
    Divider,
    Grid,
    TextField,
    Typography,
    makeStyles
} from '@material-ui/core';
import avatar1 from '../../assets/images/avatars/default.png';
import { useHistory } from "react-router-dom"
import { useAuth } from "../../hooks/useAuth";
import { useState, useEffect } from 'react';
import { uri } from '../../config/uri';
import axios from 'axios';
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Moment from 'react-moment';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// import { Form, Button } from 'react-bootstrap';
const useStyles = makeStyles((theme) => ({
    root:  {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    avatar: {
        height: 100,
        width: 100,
        margin: 10,
      },
      card: {
          display: 'flex',
          margin: 'auto'
      },
      cardDetails: {
          flex: 1,
      },
  }));

/**
 * SingleBooking component displays data about a booking including customer name, status, and date.
 *
 * @component
 */
function SingleBooking(props) {
    const classes = useStyles();
    const history = useHistory();
    const { user, logout } = useAuth();
    const booking = props.booking;
    const [sellerUser, setSellerUser ] = useState();
    const [customerUser, setCustomerUser ] = useState();
    const [cancel, setCancel] = useState(false);

    useEffect(() => {
      const fetchSellerUser = async() => {
          const res = await axios.get(`${uri}/user/`, { params: { user_id: booking.sellerid }});
          console.log(res.data);
          setSellerUser(res.data);
      }
      fetchSellerUser();

      const fetchCustomerUser = async() => {
        const res = await axios.get(`${uri}/user/`, { params: { user_id: booking.userid }});
        console.log(res.data);
        setCustomerUser(res.data);
    }
    fetchCustomerUser();
    }, []);


    /**
     * Redirects the user to the Messages page where they can message the customer.
     *
     * @function
     */
    function sendMessage() {
        if (booking.sellerid == user.id) {
          history.push({
            pathname: `/messages/${customerUser.id}`,
            state: {userid: user.id}
          });
        } else {
          history.push({
            pathname: `/messages/${sellerUser.id}`,
            state: {userid: user.id}
          });
        }
    }

    const issue_refund = async() =>{
      const data = {payment_intent: booking.stripeid};
      const response = await axios.post(`${uri}/refund`, data);
      const refund_response = await response.data;
      console.log(refund_response);     
      
      if (!response.error){
        props.booking.bookingstatus = "Refunded";
      }
      //console.log(props.booking.bookingstatus);
      setCancel(true);
    }


    return (
      <>
      <Grid item xs={12} md={12}>
          <Card className={classes.card}>
            <CardMedia>
                <Avatar className={classes.avatar} src={user.photo_urls ? user.photo_urls[0] : customerUser.profilePicture} />
            </CardMedia>
            <div className={classes.cardDetails}>
              <CardContent>
                <Typography component="h2" variant="subtitle1">
                  <strong>Customer:</strong> {customerUser && customerUser.first_name && <>{customerUser.first_name} {customerUser.last_name}</>}
                </Typography>
                <Typography component="h2" variant="subtitle1">
                  <strong>Seller:</strong> {sellerUser && sellerUser.first_name && <>{sellerUser.first_name} {sellerUser.last_name}</>}
                </Typography>
                <Typography variant="subtitle1" >
                  <strong>Price:</strong> ${booking.price}/hour
                </Typography>
                <Typography variant="subtitle1" >
                  <strong>Date:</strong> <Moment format="MM/DD/YYYY HH:SS A">{booking.timestart}</Moment> - <Moment format="HH:SS A">{booking.timeend}</Moment>
                </Typography>
                <Typography variant="subtitle1" >
                  <strong>Status: </strong> 
                  {props.booking.bookingstatus && props.booking.bookingstatus == 'paid' && 
                    <p className="h-auto py-0 px-3 badge badge-success" style={{ lineHeight: 1.5 }}>
                      {props.booking.bookingstatus}
                    </p>}
                  {props.booking.bookingstatus && props.booking.bookingstatus != 'paid' && 
                    <p className="h-auto py-0 px-3 badge badge-warning" style={{ lineHeight: 1.5 }}>
                      {props.booking.bookingstatus}
                    </p>}
                </Typography>
                <Button variant="outlined" onClick={issue_refund} className="m-2 text-danger">Cancel</Button>
                <Button variant="contained" color="secondary" onClick={sendMessage}>Send Message</Button>
              </CardContent>
            </div>
          </Card>
          <br />
      </Grid>
      <Snackbar open={cancel} autoHideDuration={6000} 
            onClose={() => setCancel(false)}>
                <Alert severity={"success"}>
                    Refund Successful
                </Alert>
      </Snackbar>
      </>
    )
}
export default SingleBooking;
