import React, {useState} from 'react';
import './Banner.css'
import { Button } from '@material-ui/core';
import Search from '../search/Search'
import {useHistory} from 'react-router-dom'

/**
 * Banner component displays a featured image and button to search nearby pros.
 *
 * @component
 */
function Banner() {
    const [showSearch, setShowSearch] = useState(false);
    const history = useHistory();

    return (
        <div className = 'banner'>
            {/* <div className='banner_search'>
                {showSearch && <Search></Search>}

                <Button onClick = { () => setShowSearch(!showSearch)}
                className = 'banner_searchButton'
                variant = 'outlined'>{showSearch?"Hide":"Search Dates"}</Button>

            </div> */}
            <div className = 'banner_info'>
                <h1 className='banner_info_text'>Creative Services Done Right</h1>
                <h5 className='banner_info_text'>Personalize your vibe in a whole new way!</h5>
                <Button onClick={()=>history.push('/search')} value="outlined">Explore Nearby</Button>
            </div>
        </div>
    )
}

export default Banner
