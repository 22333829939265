import React from 'react'
import './Bookings.css';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useAuth } from "../../hooks/useAuth";
// import SearchIcon from '@material-ui/icons/Search';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
// import {Avatar} from '@material-ui/core';
// import {Link} from 'react-router-dom'
// import { Button } from '@material-ui/core';
// import {useHistory} from 'react-router-dom'
// import Container from '@material-ui/core/Container';
// import Grid from '@material-ui/core/Grid';
import SingleBooking from '../reusable-components/SingleBooking'
import { makeStyles } from '@material-ui/core';
import { uri } from '../../config/uri';
import History from '@material-ui/icons/History'

const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3)
    },
}));
/**
 * Bookings component displays all of the user's current, pending, and past bookings.
 *
 * @component
 */
function BookingsPage() {
    const { user, logout } = useAuth();
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [bookings, setBookings] = useState([]);
    const [bookings_as_seller, setBookingsAsSeller] = useState([]);
    // const history = useHistory();

    useEffect(() => {
        const fetch_bookings_as_customer = async() => {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/sessions/${user.id}`);
            const data = await response.data;
            console.log(data);
            setBookings([...data]);
            setLoading(false);
            return data;
        }
        fetch_bookings_as_customer();

        const fetch_bookings_as_seller = async() => {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/sessions/`, { params: { sellerId: user.id }})
            const data = await response.data;
            console.log(data);
            setBookingsAsSeller([...data]);
            setLoading(false);
            return data;
        }
        fetch_bookings_as_seller();
    }, [])

    /**
     * Redirect the user to the Messages page where they can message a specified customer.
     *
     * @function
     * @param {string} userid - Customer User ID
     */
    function sendMessage(userid) {
        console.log(userid)
    }

    const convertToBooking = (b) => {
        const booking = {
            listingid: b.metadata.listing_id,
            userid: b.metadata.user_id,
            timestart: b.metadata.chosen_time_slot.split("-")[0],
            timeend: b.metadata.chosen_time_slot.split("-")[1],
            stripeid: b.payment_intent,
            price: (parseInt(b.metadata.listing_amount)/100).toString(),
            bookingstatus: b.payment_status,
            sellerid: b.metadata.seller_id
        };
        console.log(booking);
        return booking;
    }
    //if (!loading) convertToBooking(bookings[0]); 
    return (
        <div className = {classes.root}>
            <div>   
            <h3 style={{textAlign:"center"}}><History className='largeIcon'/> My Bookings</h3>
            <hr></hr>
            </div>
            {bookings.length > 0 && <h6>My Customer Bookings</h6>}
        {   
            
            (!loading) ? bookings.map((b, index) => {return(<SingleBooking key={index} booking={convertToBooking(b)} />)}) 
                : <p>No bookings.</p>            
        }
        {bookings_as_seller.length > 0 && <h6>My Seller Bookings</h6>}
        {   
            
            (!loading) ? bookings_as_seller.map((b, index) => {return(<SingleBooking key={index} booking={convertToBooking(b)} />)}) 
                : <p>No bookings.</p>            
        }
        </div>
    )
}

export default BookingsPage
