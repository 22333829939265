import { useState, createContext, useContext, useEffect} from "react";
import { auth, googleProvider } from "../config/firebase";
import axios from "axios";
import { uri } from '../config/uri';

export function useAuthProvider() {
    const [user, setUser] = useState();
    const [isLoading, setIsLoading] = useState(true)

    async function getUserAdditionalData(firebaseUser) {
        try {
            const res = await axios.get(`${uri}/user/email/${firebaseUser.email}`);
            const token = await firebaseUser.getIdToken()
            res.data.token = token
            setUser(res.data)
        } catch (e) {

        }
    }

    async function handleAuthStateChange(firebaseUser) {
        if (firebaseUser && !user) {
            await getUserAdditionalData(firebaseUser);
        }
        setIsLoading(false)
    };

    useEffect(() => {
        const unsub = auth.onAuthStateChanged(handleAuthStateChange)
        return () => {
            unsub()
        }
    }, [])


    async function createUserOnBackend(firstName, lastName, email) {
        try {
            const res = await axios.post(`${uri}/signup/`, {
                first_name: firstName,
                last_name: lastName,
                email: email,
            });
            const user = res.data;
            setUser(user);
            return user
        } catch (e) {
            console.log(e);
        }
    }

    async function getUserOnBackend(email) {
        try {
            const url = `${uri}/login/${email}`
            const res = await axios.get(url)
            const user = res.data;
            setUser(user);
        } catch (e) {
            throw e
            console.log(e);
        }
    }

    async function signUp(firstName, lastName, email, password) {
        const res = await auth.createUserWithEmailAndPassword(email, password);
        return await createUserOnBackend(firstName, lastName, email);
    }


    async function login(email, password) {
        const res = await auth.signInWithEmailAndPassword(email, password)
        return await getUserOnBackend(email)
    }

    async function logout() {
        const res = await auth.signOut()
        setUser(null)
        return res
    }

    async function resetPassword(email) {
        await auth.sendPasswordResetEmail(email);
    }

    async function loginWithGoogle() {
        const res = await auth.signInWithPopup(googleProvider)
        await getUserOnBackend(res.user.email)
    }

    async function signUpWithGoogle() {
        const res = await auth.signInWithPopup(googleProvider)
        const {displayName, email} = res.user
        const splitName = displayName.split(' ') 
        const firstName = splitName[0]
        const lastName = splitName[1] 

        getUserOnBackend(email)
       
        if (user == null) {
            await createUserOnBackend(firstName, lastName, email)
        }
    }

    return {
        user,
        isLoading,
        signUp,
        login,
        logout,
        loginWithGoogle,
        signUpWithGoogle,
        resetPassword
    };
}

export const authContext = createContext({ user: {} });

export const useAuth = () => {
    return useContext(authContext);
};
