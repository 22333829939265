import React from 'react'
import SingleListing from './SingleListing'

export default function ListingList({listings}) {
    return (
        <div>
            {listings.map((listing) => (
                <div key={listing.id}>
                    <SingleListing  listing={listing} />
                    <br />
                </div>
            ))}
        </div>
    )
}
