import React from 'react'
import axios from 'axios'
import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import { uri } from '../../config/uri';

import {
  Card,
  CardContent,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import SingleReview from '../reusable-components/SingleReview'
import ProfileSnippet from '../reusable-components/ProfileSnippet'
import { useAuth } from "../../hooks/useAuth";
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import MyListingsPage from '../mylistings/MyListings';
import BookmarksPage from '../bookmarks/Bookmarks';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  avatar: {
    height: 100,
    width: 100
  }
}));

const states = [
  {
    value: 'alabama',
    label: 'Alabama'
  },
  {
    value: 'new-york',
    label: 'New York'
  },
  {
    value: 'san-francisco',
    label: 'San Francisco'
  }
];

/**
 * MyProfile component displays the user's public data including name, location, listings, and reviews.
 *
 * @component
 */
function MyProfilePage() {
  const classes = useStyles();
  const { user, logout } = useAuth();
  const [stats, setStats] = useState({});

  const review = {
    listingid: '',
    userid: '',
    review: 'Excellent photos. Jane came highly recommended and she lived up to them. Cannot recommend enough. Will be back. This was a great experience, especially considering the low price.',
    rating: 3.5,
    date: '02/02/2020'
  };

  const [listings, setListings] = useState([]);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    /**
     * Retrieves listing data from backend.
     *
     * @function
     * @memberOf MyProfilePage
     */
    const fetchListings = async () => {
      const res = await axios.get(`${uri}/listings/`, {
        params: {
          user_id: user.id 
        }
      })
      setListings(res.data);
    }

    const getMySubmittedReviews = async () => {
      const res = await axios.get(`${uri}/reviews-received/`, {
          params: {
              user_receive_id: user.id
          }
      }) 
      setReviews(res.data)
    }
    fetchListings();
    getMySubmittedReviews();

    const fetchStats = async () => {
      const res = await axios.get(`${uri}/num-listings/`, { params: { user_id: user.id }})
      const res2 = await axios.get(`${uri}/num-reviews/`, { params: { user_receive_id: user.id }})
      let userStats = {
        numListings: res.data,
        numReviews: res2.data
      }
      setStats(userStats);
    }
    fetchStats();
  }, []);

  return (
    <>
      {user && (
        <Container maxWidth="lg" className={classes.root}>
          <h3 style={{textAlign:"center"}}><AccountCircleIcon className='largeIcon' /> My Profile</h3>
          <hr></hr>
          <ProfileSnippet user={user} edit={true} stats={stats} />
          <div>
          <hr></hr>
          <h6>Account Details</h6>
          <p>Email: {user.email}</p>
          {user.phone && <p>Phone: {user.phone}</p>}
          {!user.phone && <p>Phone: No phone information available.</p>}
          <hr></hr>
          </div>
        </Container>
      )}
    </>
  )
}
export default MyProfilePage
