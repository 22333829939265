import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Home from "./components/home/Home";
import SearchPage from "./components/searchPage/SearchPage";
import PostPage from "./components/post/PostPage";
import ListingsPage from "./components/listings/ListingsPage";
import Login from "./components/login/Login";
import Signup from "./components/signup/Signup";
import Profile from "./components/profile/Profile";
import PrivateRoute from "./components/PrivateRoute"
import DashboardPage from './components/dashboard/Dashboard'
import MessagesPage from './components/messages/Messages'
import BookingsPage from './components/bookings/Bookings'
import MyListingsPage from './components/mylistings/MyListings'
import BookmarksPage from './components/bookmarks/Bookmarks'
import AddListingPage from './components/addlisting/AddListing'
import AvailabilityPage from './components/availability/Availability'
import MyProfilePage from './components/myprofile/MyProfile'
import EditProfilePage from './components/editprofile/EditProfile'
import ListingPage from './components/listing/ListingPage'
import EditListingPage from './components/editlisting/EditListing'
import Payment from "./components/payment/Payment";
import AboutPage from "./components/static-pages/About";
import ContactPage from "./components/static-pages/Contact";
import BlogPage from "./components/static-pages/Blog";
import TermsAndConditionsPage from "./components/static-pages/Terms&Conditions";
import PrivacyPolicyPage from "./components/static-pages/PrivacyPolicy";
import HelpAndFAQsPage from "./components/static-pages/Help&FAQs";
import ListYourServicePage from "./components/static-pages/ListYourService";
import MakeMoneyOnVersaPage from "./components/static-pages/MakeMoneyOnVersa";
import HowItWorksPage from "./components/static-pages/HowItWorks";
import BookingConfirmationPage from "./components/bookingconfirmation/BookingConfirmation";
import ResetPassword from "./components/reset-password/ResetPassword";


export default function Routes() {
    return (
    <Switch>
        <Route path="/search">
            <SearchPage />
        </Route>

        <Route path="/login">
            <Login />
        </Route>

        <Route path="/signup">
            <Signup />
        </Route>

        <Route path="/reset-password">
            <ResetPassword />
        </Route>

        <Route path="/payment">
            <Payment />
        </Route>

        <PrivateRoute path="/dashboard" component={DashboardPage} />

        <PrivateRoute path="/messages/:otheruserid" component={MessagesPage} />

        <PrivateRoute path="/bookings" component={BookingsPage} />

        <PrivateRoute path="/mylistings" component={MyListingsPage} />

        <PrivateRoute path="/editlisting/:id" component={EditListingPage} />

        <PrivateRoute path="/listing/:id" component={ListingPage} />

        <PrivateRoute path="/myreviews" component={BookmarksPage} />

        <PrivateRoute path="/addlisting" component={AddListingPage} />

        <PrivateRoute path="/availability" component={AvailabilityPage} />

        <PrivateRoute path="/myprofile" component={MyProfilePage} />

        <PrivateRoute path="/editprofile" component={EditProfilePage} />

        <PrivateRoute path="/confirmation" component={BookingConfirmationPage} />

        <Route path="/post">
            <PostPage />
        </Route>

        <Route path="/listings">
            <ListingsPage />
        </Route>

        {/* <Route path='/dashboard'>
            <DashboardPage/>
        </Route>

        <Route path='/messages'>
            <MessagesPage/>
        </Route>

        <Route path='/bookings'>
            <BookingsPage/>
        </Route>

        <Route path='/mylistings'>
            <MyListingsPage/>
        </Route>

        <Route path='/bookmarks'>
            <BookmarksPage/>
        </Route>

        <Route path='/addlisting'>
            <AddListingPage/>
        </Route>

        <Route path='/availability'>
            <AvailabilityPage/>
        </Route>

        <Route path='/myprofile'>
            <MyProfilePage/>
        </Route>

        <Route path='/editprofile'>
            <EditProfilePage/>
        </Route> */}

        <Route path='/listing'>
            <ListingPage/>
        </Route>

        <Route path="/about">
            <AboutPage />
        </Route>

        <Route path="/contact">
            <ContactPage />
        </Route>

        <Route path="/blog">
            <BlogPage />
        </Route>

        <Route path="/termsconditions">
            <TermsAndConditionsPage />
        </Route>

        <Route path="/privacy">
            <PrivacyPolicyPage />
        </Route>

        <Route path="/help">
            <HelpAndFAQsPage />
        </Route>

        <Route path="/listyourservice">
            <ListYourServicePage />
        </Route>

        <Route path="/makemoneyonversa">
            <MakeMoneyOnVersaPage />
        </Route>

        <Route path="/howitworks">
            <HowItWorksPage />
        </Route>

        <Route path="/">
            <Home />
        </Route>
    </Switch>
    )
}
