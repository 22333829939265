import React, {useState, useEffect } from 'react';
import axios from 'axios';
import './Bookmarks.css';
// import SearchIcon from '@material-ui/icons/Search';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
// import {Avatar} from '@material-ui/core';
// import {Link} from 'react-router-dom'
// import { Button } from '@material-ui/core';
// import {useHistory} from 'react-router-dom'
// import Container from '@material-ui/core/Container';
// import Grid from '@material-ui/core/Grid';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    Divider,
    Grid,
    TextField,
    Typography,
    makeStyles
} from '@material-ui/core';
import SingleReview from '../reusable-components/SingleReview'
import RateReviewIcon from '@material-ui/icons/RateReview';
import { useAuth } from '../../hooks/useAuth';
import { uri } from '../../config/uri';

const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3)
    },
}));

/**
 * Component comment
 *
 * @component
 */
function BookmarksPage() {
    const classes = useStyles();
    const {user} = useAuth(); 

    const [reviews, setReviews] = useState([]);
    const [reviewsReceived, setReviewsReceived] = useState([]);

    useEffect(() => {
        const getMyReviews = async () => {
            const res = await axios.get(`${uri}/reviews-submitted/`, {
                params: {
                    user_submit_id: user.id
                }
            }) 
            console.log(res.data)
            setReviews(res.data)
        }
        getMyReviews();

        const getMyReviewsReceived = async () => {
            const res = await axios.get(`${uri}/reviews-received/`, {
                params: {
                    user_receive_id: user.id
                }
            }) 
            console.log(res.data)
            setReviewsReceived(res.data)
        }
        getMyReviewsReceived();
    }, [])

    return (
        <div className = {classes.root}>
            <h3 style={{textAlign:"center"}}><RateReviewIcon className='largeIcon'/> My Reviews</h3>
            <hr></hr>
            {reviews.length > 0 && <h6>My Submitted Reviews</h6>}
            {reviews.map((review) => (
                <Card key={review.id}>
                    <CardContent>
                      <SingleReview showListing={true} review={review} />
                    </CardContent>
                </Card>
            ))} 
            <br />
            {reviewsReceived.length > 0 && <h6>My Received Reviews</h6>}
            {reviewsReceived.map((review) => (
                <Card key={review.id}>
                    <CardContent>
                      <SingleReview showListing={true} review={review} />
                    </CardContent>
                </Card>
            ))} 

        </div>
    )
}

export default BookmarksPage
