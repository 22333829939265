import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Paper from '@material-ui/core/Paper';
import StarRatings from 'react-star-ratings';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Container,
    Divider,
    Grid,
    TextField,
    Typography,
    makeStyles
} from '@material-ui/core';
import avatar1 from '../../assets/images/avatars/avatar1.jpg';
import { useAuth } from '../../hooks/useAuth';
import { uri } from '../../config/uri';
import {useHistory} from 'react-router-dom';


const useStyles = makeStyles(() => ({
    root: {},
    avatar: {
      height: 100,
      width: 100,
      margin: 10,
    },
    card: {
        display: 'flex',
        margin: 'auto'
    },
    cardDetails: {
        flex: 1,
    },
    cardMedia: {
    },
  }));

/**
 * SingleReview component displays one review including the customer's name, star rating, and review.
 *
 * @component
 */
function SingleReview(props) {
    const classes = useStyles();
    // const history = useHistory();
    const {user} = useAuth();
    const history = useHistory();
    const {review, showListing, showCustomer} = props;
    const [listing, setListing] = useState()
    const [customer, setCustomer] = useState()
    const [editMode, setEditMode] = useState(false);

    const [editComment, setEditComment] = useState('')
    const [editRating, setEditRating] = useState(review.rating)

    useEffect(() => {
      const getListing = async () => {
        const res = await axios.get(`${uri}/listing/`, {
          params: {
              id: review.listing_id
          }
        }) 
        setListing(res.data) 
      }
      const getCustomer = async () => {
        const res = await axios.get(`${uri}/user/`, {
          params: {
              user_id: review.user_submit_id
          }
        }) 
        setCustomer(res.data)
      }
      getListing();
      getCustomer();
    }, [])

    function goToListing() {
        history.push({
            pathname: `/listing/${listing.id}`,
            state: {listing: listing}
          });
    }

    function editReview() {
      setEditMode(true);
    }

    function cancelEdit() {
      setEditMode(false);
    }

    async function saveEdit() {
      if (!editComment || editComment ==="") {
        alert('Please enter your comment');
        return;
      }
      const body = {
        id: review.id,
        comment: editComment,
        rating: editRating
      }
      const res = await axios.put(`${uri}/review/`, body);
      window.location.reload();
    }

    function onCommentChange(e) {
      setEditComment(e.target.value);
    }

    async function deleteReview() {
      await axios.delete(`${uri}/review`, {data: {
        id: review.id
      }})
      window.location.reload();
    }

    return (
      <Grid item xs={12} md={12}>
        {/* <CardActionArea component="a" href="#"> */}
          <Card className={classes.card} variant="outlined">
            <div className={classes.cardDetails}>
              <CardContent>
                <Typography component="h2" variant="h5" gutterBottom>
                  {showCustomer && customer && `${customer.first_name} ${customer.last_name}`}
                </Typography>
                <Typography component="h2" variant="h5">
                  {showListing && listing && `Listing: ${listing.title}`}
                </Typography>
                <div style={{marginBottom: 10}}>
                    {!editMode && <StarRatings
                    rating={review.rating}
                    starRatedColor="#ffc600"
                    starDimension="20px"
                    starSpacing="2px"
                    name='rating'
                     />}
                     {editMode && <StarRatings
                          rating={editRating}
                          starRatedColor="#ffc600"
                          starDimension="20px"
                          starSpacing="2px"
                          name='rating'
                          changeRating={setEditRating}
                          />}
                </div>
                {!editMode &&
                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                  {review.comment}
                </Typography>
}
                {editMode && <TextField variant="outlined" fullWidth onChange={(e) => onCommentChange(e)}/>}
                <Grid container direction="row" spacing={1}>
                  <Grid item>
                {user.id == review.user_submit_id && !editMode && <Button color="secondary" variant="outlined" onClick={editReview}>Edit</Button>}
                  </Grid>
                  <Grid item>
                {user.id == review.user_submit_id && editMode && <Button color="secondary" variant="outlined" onClick={saveEdit}>Save</Button>}
                  </Grid>
                  <Grid item>
                {user.id == review.user_submit_id && editMode && <Button color="secondary" variant="outlined" onClick={cancelEdit}>Cancel</Button>}
                  </Grid>
                  <Grid item>
                {user.id == review.user_submit_id && !editMode && <Button color="secondary" variant="outlined" onClick={deleteReview}>Delete</Button>}
                  </Grid>
                  <Grid item>
                {showListing && !editMode && <Button variant="outlined" onClick={goToListing}>View Listing</Button>}
                  </Grid>
                </Grid>

              </CardContent>
            </div>
          </Card>
        {/* </CardActionArea> */}
      </Grid>
    )
}
export default SingleReview;
