import React from 'react'

import axios from 'axios'
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Layers from '@material-ui/icons/Layers'

import './MyListings.css';
import SingleListing from '../reusable-components/SingleListing'

import { uri } from '../../config/uri';
import ListingList from '../reusable-components/ListingList';
import { useAuth } from '../../hooks/useAuth';


const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3)
    },
}));

/**
 * MyListings component displays the active and inactive listings of the user.
 *
 * @component
 */
function MyListingsPage() {
  const classes = useStyles();

    const [listings, setListings] = useState([]);
    const {user} = useAuth();
    useEffect(() => {
      /**
       * Retrieves listing data from backend.
       *
       * @function
       * @memberOf MyListingsPage
       */
      const fetchListings = async () =>  {
        const res = await axios.get(`${uri}/listings/:id`, {
          params: {
            user_id: user.id 
          }
        })
        setListings(res.data);
      }
      fetchListings();
    }, []);

    return (
        <div className = {classes.root}>
          <h3 style={{textAlign:"center"}}><Layers className='largeIcon'/> My Listings</h3>
          <hr></hr>
          <ListingList listings={listings} />
        </div>
    )
}
export default MyListingsPage
