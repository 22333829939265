import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Dashboard from '@material-ui/icons/Dashboard'
import Chat from '@material-ui/icons/Chat'
import History from '@material-ui/icons/History'
import Layers from '@material-ui/icons/Layers'
import Bookmark from '@material-ui/icons/Bookmark'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'
import DateRange from '@material-ui/icons/DateRange'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew'
import Container from '@material-ui/core/Container';
import RateReviewIcon from '@material-ui/icons/RateReview';

import { useAuth } from "../../hooks/useAuth";

const drawerWidth = 200;

const useStyles = makeStyles(theme => ({
    root: {
      display: "flex"
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: "white",
      color: "black",
      boxShadow: "0px 2px 0px 0px #ECECEC",
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      background: '#30292F',
      color: 'white',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(0)
    },
    main: {
      padding: theme.spacing(3)
    },
    footer: {
      // padding: theme.spacing(3, 2),
      // padding: theme.spacing(0),
      marginTop: 'auto',
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
    listitem: {
      '&:hover': {
        background: "#1f1a1e",
        color: 'white',
      },
    }
}));

/**
 * SideMenu component allows the users to navigate between pages in the seller portal.
 *
 * @component
 */
function SideMenu(props) {
    const classes = useStyles();
    const { user, logout } = useAuth();
    
    /**
     * Logs out the current user.
     *
     * @function
     */
    async function logOut() {
        await logout()
    }

    const { location } = props;
    if (location.pathname == "/" || location.pathname == "/search" || location.pathname == "/listing"){
      return null;
    }
    
    return (
    <>
      {user && (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{ paper: classes.drawerPaper, }}>
        <Toolbar />
        <div className={classes.drawerContainer}>
        <List>
            <ListItem className={classes.listitem} button key="dashboard" component={Link} to="/dashboard">
                <ListItemIcon><Dashboard style={{ color: 'white' }}/></ListItemIcon>
                <ListItemText>Dashboard</ListItemText>
            </ListItem>
            {/* <ListItem className={classes.listitem} button key="messages" component={Link} to="/messages">
                <ListItemIcon><Chat style={{ color: 'white' }}/></ListItemIcon>
                <ListItemText>Messages</ListItemText>
            </ListItem> */}
            <ListItem className={classes.listitem} button key="bookings" component={Link} to="/bookings">
                <ListItemIcon><History style={{ color: 'white' }}/></ListItemIcon>
                <ListItemText>Bookings</ListItemText>
            </ListItem>
        </List>
        <Divider />
        <List>
            <ListItem className={classes.listitem} button key="mylistings" component={Link} to="/mylistings">
                <ListItemIcon><Layers style={{ color: 'white' }}/></ListItemIcon>
                <ListItemText>My Listings</ListItemText>
            </ListItem>
            <ListItem className={classes.listitem} button key="bookmarks" component={Link} to="/myreviews">
                <ListItemIcon><RateReviewIcon style={{ color: 'white' }}/></ListItemIcon>
                <ListItemText>My Reviews</ListItemText>
            </ListItem>
            <ListItem className={classes.listitem} button key="addlisting" component={Link} to="/addlisting">
                <ListItemIcon><AddCircleOutline style={{ color: 'white' }}/></ListItemIcon>
                <ListItemText>Add Listing</ListItemText>
            </ListItem>
            <ListItem className={classes.listitem} button key="availability" component={Link} to="/availability">
                <ListItemIcon><DateRange style={{ color: 'white' }}/></ListItemIcon>
                <ListItemText>Availability</ListItemText>
            </ListItem>
        </List>
        <Divider />
        <List>
            <ListItem className={classes.listitem} button key="myprofile" component={Link} to="/myprofile">
                <ListItemIcon><AccountCircleIcon style={{ color: 'white' }}/></ListItemIcon>
                <ListItemText>My Profile</ListItemText>
            </ListItem>
            <ListItem className={classes.listitem} button key="logout" onClick={logOut}>
                <ListItemIcon><PowerSettingsNew style={{ color: 'white' }}/></ListItemIcon>
                <ListItemText>Logout</ListItemText>
            </ListItem>
        </List>
        </div>
        </Drawer>
      )}
    </>
    )
}

export default withRouter(SideMenu);