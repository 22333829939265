import React from 'react'
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3)
    },
}));
/**
 * List Your Service page displays information how to list a service
 *
 * @component
 */
function ListYourServicePage() {
    const classes = useStyles();
    // const history = useHistory();

    return (
        <Container component="main" maxWidth="md" className = {classes.root}>
            <h3>List Your Service</h3>
            <hr />
            <p>Coming soon...</p>
            <hr />
        </Container>
    )
}

export default ListYourServicePage
