import React from 'react'
import Paper from '@material-ui/core/Paper';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    Divider,
    Grid,
    TextField,
    Typography,
    makeStyles
} from '@material-ui/core';
import avatar1 from '../../assets/images/avatars/avatar1.jpg';
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import LayersIcon from '@material-ui/icons/Layers';
import RateReviewIcon from '@material-ui/icons/RateReview';
import ChatIcon from '@material-ui/icons/Chat'
import {useHistory} from 'react-router-dom'

const useStyles = makeStyles(() => ({
    root: {},
    avatar: {
      height: 100,
      width: 100
    }
  }));

/**
 * ProfileSnippet component displays the user's basic data such as name, location, and avatar.
 *
 * @component
 */
function ProfileSnippet(props) {
    const classes = useStyles();
    const history = useHistory();
    const user = props.user;
    const stats = props.stats;
    var edit = false;
    if (props.edit){
        edit = props.edit;
    }

    function messageSeller() {
        history.push({
            pathname: `/messages/${user.userid}`,
          });
      }

    const [location, setLocation] = useState({});
    /**
     * Retrieves the user's location data from the backend.
     *
     * @function
     */
    function getUserLocation() {
        // TODO: GET REQUEST TO BACKEND FOR USER LOCATION INFO
        return {
            neighborhood: "DummyNeighborhood",
            city: "New York City",
            state: "NY",
            zipcode: 10025,
            country: "USA"
        };
    }

    useEffect(() => {
        setLocation(getUserLocation());
      }, []);

    return (
    <div>
        <Grid container spacing={0}>
            <Grid item container xs={12}>
                <Grid item xs={2} align = "center">
                    <Avatar className={classes.avatar} src={user.avatar} />
                    {edit && <Button className="m-2" size="small" color="primary" variant="outlined" key="editprofile" component={Link} to="/editprofile">
                        Edit Profile
                    </Button>}
                    {!edit && <Button className="m-2" size="small" color="primary" variant="contained" onClick={messageSeller} startIcon={<ChatIcon />}>
                        Send Message
                    </Button>}
                </Grid>
                <Grid item xs={8}>
                    <div className='searchResult_info'>
                        <div className = 'searchResult_info_top'>
                            <h3>{user.first_name} {user.last_name}</h3>
                            {/* <p>{location.city}, {location.state}</p> */}
                            <p><VerifiedUserIcon />Verified &#8226; <LayersIcon /> {stats.numListings} Listings &#8226; <RateReviewIcon /> {stats.numReviews} Reviews</p>
                            {user.bio && <p>{user.bio}</p>}
                            {!user.bio && <p>No bio information available.</p>}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={2}></Grid>
            </Grid>
        </Grid>
    </div>
    )
}
export default ProfileSnippet;
