import React, {useState, useEffect} from 'react'
import StarRatings from 'react-star-ratings';
import {useHistory} from 'react-router-dom'
import {
    Button,
    ButtonGroup,
    Card,
    CardContent,
    CardMedia,
    Grid,
    Typography,
    makeStyles
} from '@material-ui/core';
import axios from 'axios';
import {uri} from '../../config/uri';
import { useAuth } from '../../hooks/useAuth';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import '../searchResult/SearchResult.css'


const useStyles = makeStyles(() => ({
    root: {},
    avatar: {
      height: 100,
      width: 100
    },
    card: {
        display: 'flex',
        margin: 'auto'
    },
    cardDetails: {
        flex: 1,
    },
    cardMedia: {
      height: 200,
      width: '100%',
      objectFit: 'cover',
      borderRadius: '10px'
    },
    leftalignbutton: {
      justifyContent: 'flex-start'
    }
  }));

/**
 * SingleListing component displays the basic data about a listing including name, price, and description.
 *
 * @component
 */
function SingleListing(props) {
    const classes = useStyles();
    const history = useHistory();
    const { user } = useAuth();
    const {listing, handleDelete } = props
    const [rating, setRating] = useState();
    const placeholder = "https://via.placeholder.com/1000"

    /**
     * Redirects the user to the Listing page.
     *
     * @function
     */
    function goToListing() {
      history.push({
          pathname: `/listing/${listing.id}`,
          state: {listing: listing}
        });
    }

    /**
     * Redirects the user to the EditListing page.
     *
     * @function
     */
    function editListing() {
      history.push({
        pathname: `/editlisting/${listing.id}`
      });
    }

    /**
     * Deletes the current listing from the backend.
     *
     * @function
     */
    function deleteListing() {
      axios.delete(`${uri}/listing`, {data: {
        id: listing.id
      }})
      window.location.reload(false);
    }
    useEffect(() => {
      let numReviews = 0;
      let totalRating = 0;
      listing.reviews.forEach(review => {
        numReviews += 1;
        totalRating += review.rating;
      })
      if (numReviews != 0) {
        setRating(totalRating / numReviews);
      }
    }, []) 
      

    return (
    <div className='searchResult'>
      <Grid container spacing={2}>
        <Grid item xs={4} onClick={goToListing}>
          <img src={listing.photo_urls.length > 0 ? listing.photo_urls[0].url : placeholder} className={classes.cardMedia} />
        </Grid>
        <Grid item xs={7} onClick={goToListing}>
            <div className='searchResult_info'>
                <div className = 'searchResult_info_top'>
                    <p>{listing.location.city}, {listing.location.state}</p>
                    <h3>{listing.title}</h3>
                    <p>____</p>
                    <p>{listing.description}</p>
                </div>
                <div className = 'searchResult_info_bottom'>
                  <div className="searchResult_stars">
                    <StarRatings
                        rating={rating}
                        starRatedColor="#ffc600"
                        starDimension="15px"
                        starSpacing="2px"
                        name='rating'
                    />
                    {rating && <p>({rating})</p>}
                  </div>
                </div>
            </div>
        </Grid>
        <Grid item container xs={1}>
          {user && user.id == listing.user_id &&
            <Grid item>
              <ButtonGroup orientation="vertical">
                <Button className={classes.leftalignbutton} startIcon={<EditIcon />} onClick={editListing}>
                  Edit
                </Button>
                <Button className={classes.leftalignbutton} startIcon={<DeleteIcon />} onClick={deleteListing}>
                  Delete
                </Button>
              </ButtonGroup>
            </Grid>}
          <Grid item container alignItems="flex-end" justify="flex-end">
            <h5>${listing.price}/hr</h5>
          </Grid>
        </Grid>
      </Grid>
    </div>
    )
}
export default SingleListing;
