import React, { useState, useEffect } from 'react';
import CalendarTemplate from '../calendarTemplate/Calendar';
import {Container} from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import './Availability.css';
import { makeStyles } from '@material-ui/core';
import { useAuth } from "../../hooks/useAuth";
import { uri } from '../../config/uri';
import axios from 'axios';

// Source: https://www.npmjs.com/package/availability-calendar-react

const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3)
    },
}));
/**
 * Availability component allows users to input their availability via a calendar format.
 *
 * @component
 */

function AvailabilityPage() {
    const classes = useStyles();
    const { user, logout } = useAuth();
    const [availability, setAvailability] = useState([])
    const Calendar = CalendarTemplate({
    availability,
    setAvailability
    
    })

    useEffect(() => {
        const parseDateTimesFromServer = async () => {
            var userid = null;
            if(user) {
              userid = user.id;
            }
            const res = await axios.get(`${uri}/availabilities/`, { params: { user_id: userid }})
            
            console.log('trying to fetch and set the already stored availabilities')
            console.log(res.data)
            var start_timestamps = new Set()
            var jsonAvl = []
            // const timestampset = new Set([])
            for (var i=0; i<res.data.length;i++){
                var jsonData = {};
                var start = new Date(res.data[i].start_timestamp*1000)
                var end = new Date(res.data[i].end_timestamp*1000)
                jsonData["start"] = start
                jsonData["end"] = end
                var curPair = [start,end]
                // console.log(curPair)
                if (!start_timestamps.has(res.data[i].start_timestamp)){
                    jsonAvl.push(jsonData)
                    start_timestamps.add(res.data[i].start_timestamp)
                }
                else{
                  // console.log('dupl pair found')
                }
              }
            console.log(jsonAvl)
            setAvailability(jsonAvl)
          }
          parseDateTimesFromServer();
    }, []);

    

    return (
        <Container maxWidth="lg" className = {classes.root}>
            <div>
                <h3 style={{textAlign:"center"}}><DateRangeIcon className='largeIcon'/> Availability</h3>
                <hr></hr>
                <Calendar/>
            </div>
    </Container>
    );
}
export default AvailabilityPage