import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import { useAuth } from "../../hooks/useAuth";
import { useHistory } from "react-router-dom"
import { useState, useEffect } from 'react';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(3)
      },
    paper: {
        marginTop: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    },
}));

/**
 * Login component allows the user to login to their account.
 *
 * @component
 */
function Login() {
    const classes = useStyles();
    const { login, loginWithGoogle} = useAuth()
    const { register, errors, handleSubmit } = useForm();
    const history  = useHistory()
    const [errorMsg, setErrorMsg] = useState();
    const [error, setError] = useState(false);

    /**
     * Logs in users via email and password.
     *
     * @function
     * @param {Object} data - Email, Password
     */
    async function onSubmit(data) {
        try {
            await login(data.email, data.password);
            history.push("/myprofile")
        } catch (err) {
            console.log(err);
            // alert(err)
            setErrorMsg(err.message);
            setError(true);
        }
    }

    /**
     * Initiates login via Google.
     *
     * @function
     */
    async function googleLogin() {
        try {
            await loginWithGoogle();
            history.push("/myprofile")
        } catch (err) {
            if (err.response.data) {
                // alert(err.response.data.detail);
                setErrorMsg(err.response.data.detail);
                setError(true);
            }
        }
    }

    return (
        <Container component="main" maxWidth="xs" className = {classes.root}>
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                {error && <MuiAlert className="mb-4" severity="error">
                    <div className="d-flex align-items-center align-content-center">
                    <span>
                        <strong className="d-block">Error: </strong>{errorMsg}
                    </span>
                    </div>
                </MuiAlert>}
                <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        inputRef={register({ required: true })}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        inputRef={register({ required: true })}
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        style={{backgroundColor: '#6136D8'}}
                    >
                        Sign In
                    </Button>
                </form>
                <Typography component="p" variant="body1">OR</Typography>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    onClick={() => googleLogin()}
                >
                    Sign in with Google
                </Button>
                <Grid container>
                        <Grid item xs>
                            <Link href="/reset-password" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/signup" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
            </div>
        </Container>
    );
}

export default Login;