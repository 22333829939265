import React from 'react'
import Paper from '@material-ui/core/Paper';
import StarRatings from 'react-star-ratings';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Container,
    Divider,
    Grid,
    TextField,
    Typography,
    makeStyles
} from '@material-ui/core';


const useStyles = makeStyles(() => ({
    root: {},
    card: {
        display: 'flex',
        margin: 'auto'
    },
    cardIcon: {
        color: props => props.iconColor
    }
  }));

/**
 * Widget component displays a statistic about a user's account.
 *
 * @component
 */
function Widget(props) {
    const {classes, cardIcon} = useStyles(props);
    // const history = useHistory();
    
    const stat = props.stat;
    const description = props.description;
    const cardColor = props.cardColor;
    const Icon = props.icon;
    const iconColor = props.iconColor;

    return (
        <Grid item xs={3} sm={3} md={3}>
          <Card className={`card-box border-0 text-light mb-4 ${cardColor}`}>
          {/* <Card className="card-box bg-gradient-purple border-0 text-light mb-4"> */}
            <CardContent className="p-3">
              <div className="d-flex align-items-start">
                <div className="font-weight-bold">
                  <small className="text-white-50 d-block mb-1 text-uppercase">
                    {description}
                  </small>
                  <span className="font-size-xxl mt-1">{stat}</span>
                </div>
                <div className="ml-auto">
                  <div className="bg-white text-center d-50 rounded-circle d-flex align-items-center justify-content-center">
                    {/* <Layers className="font-size-xl" style={{ color: '#6136D8' }} /> */}
                    <Icon className={`font-size-xl ${cardIcon}`} />
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
    )
}
export default Widget;
