import React from 'react';
import postData from '../../Post.js';


// /**
//  * Component comment
//  *
//  * @component
//  */
class PostPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
            title: '',
            description: '',
            price: '',
            pic_url: ''
        };
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.fileSelectedHandler = this.fileSelectedHandler.bind(this);
    }
  
    handleChange(event) {
      this.setState({[event.target.name]: event.target.value});
    }

    fileSelectedHandler(event) {
      this.setState({pic_url: "http://127.0.0.1:8887/" + event.target.files[0].name});
    }
  
    handleSubmit(event) {
    //   alert('A name was submitted: ' + this.state.value);
        const jsonObj = {
            title: this.state.title,
            description: this.state.description,
            price: parseInt(this.state.price),
            pic_url: this.state.pic_url
        }
        console.log(jsonObj);
        postData(jsonObj);
        setTimeout(() => {window.location.href = "/listings";}, 1000);
        event.preventDefault();
    }
  
    render() {
      return (
        <form onSubmit={this.handleSubmit}>
          <label>
            Name:
            <input name="title" type="text" value={this.state.title} onChange={this.handleChange} />
          </label>
          <label>
            Description:
            <input name="description" type="text" value={this.state.description} onChange={this.handleChange} />
          </label>
          <label>
            Price:
            <input name="price" type="text" value={this.state.price} onChange={this.handleChange} />
          </label>
          <input type="submit" value="Submit" />
          <input type="file" onChange={this.fileSelectedHandler} /> 


        </form>
      );
    }
  }

export default PostPage