import React from 'react';
import './SearchResult.css'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import DeleteIcon from '@material-ui/icons/Delete';
import StarIcon from '@material-ui/icons/Star';
import deleteData from '../../Delete.js';

// /**
//  * Component comment
//  *
//  * @component
//  */
function SearchResult({img, id, title, description, star, price, total}) {
    function handleDelete(deleteId) {
        const jsonObj = {
            id: deleteId
        }
        console.log(jsonObj);
        deleteData(jsonObj);
        setTimeout(() => {window.location.href = "/listings";}, 1000);
    }
    return (
        <div className='searchResult'>
            <img src = {img} alt=''></img>
            <FavoriteBorderIcon className = 'searchResult_heart'/>
            <DeleteIcon className= 'searchResult_delete' onClick={() => handleDelete(id)} />
            <div className='searchResult_info'>
                <div className = 'searchResult_info_top'>
                    <p>San Jose, CA</p>
                    <h3>{title}</h3>
                    <p>____</p>
                    <p>{description}</p>
                </div>
                <div className = 'searchResult_info_bottom'>
                    
                    <div className="searchResult_stars">
                        <p><strong><StarIcon/> 5</strong></p>
                    </div>
                    

                </div>
                <div className="searchResults_price">
                    <h2>{price}</h2>
                    <p>{price}</p>
                </div>
            </div>
        </div>
    )
}

export default SearchResult
