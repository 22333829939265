import React from "react";
import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const stripePromise = loadStripe("pk_test_51ISvwZLWLGCBYpjLTIrQQ1OBBGMMpng0wsWueDLludKdipdSgbHzfvqJiK8QhA8DgC1xMeBn7ZMbEXZoa5XqgEcn00Gnt04a00");
const axios = require('axios');

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(3)
      },
    paper: {
        marginTop: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    },
}));

/**
 * Payment component facilitates payment through Stripe.
 *
 * @component
 */
const Payment = () => {
    const [price, setPrice] = useState(0);
    const [productName, setProductName] = useState("");
    const [msg, setMsg] = useState("");
    const history = useHistory();

    useEffect(() =>{
        const query = new URLSearchParams(window.location.search);
        if (query.get("success")) {
            setMsg("success");
        }

        if (query.get("canceled")) {
            setMsg("canceled");
        }
    }, []);

    /**
     * Initiates payment through Stripe.
     *
     * @function
     */
    const handleClick = async (e) =>{
        e.preventDefault();
        const stripe = await stripePromise;
        //const response = await fetch("/create-checkout-session", {
            //method: "POST",
        //});
        const data = {price: price,product_name: productName};
        console.log(data);
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URI}`+'/create-checkout-session',
                        data);
        const session = await response.data;
        //console.log(session.id);
        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });
        
        if (result.error) {
            console.log(result.error);
            setMsg("error");
        }

        history.push("/payment");
    };

    return(
        <div>
            <form noValidate onSubmit={handleClick}>
                <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="price"
                        label="price"
                        type = "number"
                        id="sample_booking"
                        value={price}
                        onChange={e => setPrice(e.target.value)}
                />
                <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="product_name"
                        label="sample_booking"
                        id="sample_booking"
                        value={productName}
                        onChange={e => setProductName(e.target.value)}
                />
                <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{backgroundColor: '#6136D8'}}
                >
                        Submit
                </Button>
            </form>
            <Snackbar open={msg == "success"} autoHideDuration={6000}>
                <Alert severity={msg}>
                    This is a {msg} message!
                </Alert>
            </Snackbar>
        </div>
    );

}
export default Payment;