import React, {useState, useEffect} from 'react'
import './SearchPage.css'
import SearchResult from '../searchResult/SearchResult'
import {Button} from '@material-ui/core';
import {useHistory, location, useLocation} from 'react-router-dom'
import axios from 'axios';
import { uri } from '../../config/uri';
import ListingList from '../reusable-components/ListingList';
import SearchBar from '../reusable-components/SearchBar'
import { Avatar, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3)
    },
    avatar: {
        height: 150,
        width: 150
      }
}));
/**
 * SearchPage component displays the searchbar and search results.
 *
 * @component
 */
function SearchPage(props) {
    const classes = useStyles();
    const location = useLocation();
    const [listings, setListings] = useState([]);
    const [categories, setCategories] = useState([]);
    let searchInput = null;
    let proType = 0;
    let price = null;
    if (location.state) {
        searchInput = location.state.searchText;
        proType = location.state.protype;
        price = location.state.price;
    }

    useEffect(() => {
        const search = async () => {
          const params = {}
          if (searchInput) {
            params['search_term'] = searchInput;
          }
          if(proType) {
            params['category'] = proType;
          }
          if(price) {
              params['price_min'] = price[0];
              params['price_max'] = price[1];
          }
          console.log(params);
          const url = `${uri}/listings/search/`;
          console.log(url)
          const res = await axios.get(url, {
            params: params 
          })
          setListings(res.data);
        }
        search();
        const fetchCategories = async () => {
          const res = await axios.get(`${uri}/categories/`)
          setCategories(res.data);
        }
        fetchCategories();
      }, [searchInput, price, proType]);

      function capitalizeFirstChar(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
      <Container component="main" maxWidth="lg" className = {classes.root}>
        <div className='searchPage'>
            <h3 style={{textAlign:"center"}}><SearchIcon className='largeIcon' /> Search</h3>
            <SearchBar />
            <div className='searchPage_info'>
              <hr></hr>
              <h3>{listings.length} Listings</h3>
              <p>
                <strong>"{searchInput}"</strong> &#8226; Category: 
                {categories.map((option) => (
                  (proType > 0 && categories.length > 0 && proType == option.value && <strong key={option.label}> {capitalizeFirstChar(option.label)} </strong>)
                ))}
                &#8226; Price: {price[0] && <strong>${price[0]} - ${price[1]}</strong>}
              </p>
              {/* <hr /> */}
              {listings.length > 0 ?
                <>
                  <ListingList listings={listings}/>
                </>
                :
                <p><b>No listings available.</b></p>
              }
            </div>
        </div>
      </Container>
    )
}

export default SearchPage
