import React from 'react'
import clsx from 'clsx';
import { useState, useEffect } from 'react';
//   import moment from 'moment';
  import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    Divider,
    Grid,
    Input,
    TextField,
    Typography,
    makeStyles
  } from '@material-ui/core';
  import avatar1 from '../../assets/images/avatars/avatar1.jpg';
  import avatar2 from '../../assets/images/avatars/avatar2.jpg';
  import avatar3 from '../../assets/images/avatars/avatar3.jpg';
  import { useAuth } from "../../hooks/useAuth";
  import { useForm } from "react-hook-form";
  import AccountCircleIcon from '@material-ui/icons/AccountCircle'
  import {useHistory} from 'react-router-dom'
  
  const user = {
    avatar: avatar3,
    country: 'USA',
    jobTitle: 'Senior Developer',
    name: 'Jane Smith',
    timezone: 'GTM-7',
    // neighborhood: 'Manhattan',
    // city: 'New York City',
    // state: 'New York',
    // zipcode: '10025',
    bio: 'I have been a freelance photographer for the past 5 years, beginning with completing my BA in Photography.'
  };
  
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3)
    },
    avatar: {
      height: 100,
      width: 100
    }
  }));

  const states = [
    {
      value: 'AL',
      label: 'Alabama'
    },
    {
      value: 'NY',
      label: 'New York'
    },
    {
      value: 'PA',
      label: 'Pennsylvania'
    }
  ];

/**
 * EditProfile component allows users to edit details on their profile page.
 *
 * @component
 */
function EditProfilePage(props) {
    const classes = useStyles();
    const { user, logout } = useAuth();
    const [values, setValues] = useState(user);
    const { register, errors, handleSubmit } = useForm();
    const history = useHistory();
    
    /**
     * Saves changes made to the profile.
     *
     * @function
     * @param {Event} event - Profile Changes
     */
      const handleChange = (event) => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
      };


    /**
     * Saves all profile changes to the backend.
     *
     * @function
     */
    function onSubmit() {
        // TODO: POST TO BACKEND
        history.push({
          pathname: '/myprofile',
        });
    }

    /**
     * Retrieves the user's location data from the backend.
     *
     * @function
     */
    function getUserLocation() {
        // TODO: GET REQUEST TO BACKEND FOR USER LOCATION INFO
        return {
            neighborhood: "DummyNeighborhood",
            city: "New York City",
            state: "NY",
            zipcode: 10025,
            country: "USA"
        };
    }

    useEffect(() => {
      }, []);
    
    return (
        <>
        {user && (
        <Container maxWidth="lg" className = {classes.root}>
          <h3><AccountCircleIcon className='largeIcon'/> Edit Profile</h3>
          <hr></hr>
          <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
                <Card>
                    <CardContent>
                        <Box alignItems="center" display="flex" flexDirection="column">
                        <Avatar className={classes.avatar} src={user.avatar} />
                        <Typography color="textPrimary" gutterBottom variant="h3">
                            {user.first_name} {user.last_name}
                        </Typography>
                        </Box>
                    </CardContent>
                    <Divider />
                    <CardActions>
                        <Button color="primary" fullWidth variant="text" component="label" >
                        Change profile picture
                        <Input 
                            type="file" 
                            hidden
                            label="avatar"
                            name="avatar"
                            onChange={handleChange}
                            // TODO: not sure how to send pictures to backend
                        />
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
                <Card>
                    <CardHeader
                    subheader="Enter your personal details here."
                    title="Profile"
                    />
                    <Divider />
                    <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            helperText="Please specify the first name"
                            label="First name"
                            name="firstName"
                            onChange={handleChange}
                            required
                            value={values.first_name}
                            variant="outlined"
                        />
                        </Grid>
                        <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            label="Last name"
                            name="lastName"
                            onChange={handleChange}
                            required
                            value={values.last_name}
                            variant="outlined"
                        />
                        </Grid>
                        <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            label="Phone Number"
                            name="phone"
                            onChange={handleChange}
                            required
                            type="number"
                            value={values.phone}
                            variant="outlined"
                        />
                        </Grid>
                        <Grid item md={12} xs={12}>
                        <TextField
                            fullWidth
                            id="outlined-multiline-static"
                            label="Bio"
                            name="bio"
                            onChange={handleChange}
                            multiline
                            rows="4"
                            // defaultValue="Default Value"
                            value={values.bio}
                            variant="outlined"
                        />
                        </Grid>
                    </Grid>
                    </CardContent>
                    <Divider />
                    <Box display="flex" justifyContent="flex-end" p={2}>
                        <Button type="submit" color="primary" variant="contained">
                            Save details
                        </Button>
                    </Box>
                </Card>
            </Grid>
            </Grid>
          </form>
        </Container>
      )}
      </>
    )
}
export default EditProfilePage
