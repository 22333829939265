import React from 'react'
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3)
    },
}));
/**
 * How It Works page displays information about the company
 *
 * @component
 */
function HowItWorksPage() {
    const classes = useStyles();
    // const history = useHistory();

    return (
        <Container component="main" maxWidth="md" className = {classes.root}>
            <h3>How It Works</h3>
            <hr />
            <h2><i>"A dynamic marketplace created for creators to connect with the people that need their services the most."</i></h2>
            <br />
            <h4>Core Features</h4>
            <h6>Seller side optimizations:</h6>
            <p>1) Hassle free - all services are bookable in 10 clicks and under.</p>
            <p>2) Credible - User ratings are crowd-sourced and all freelancers are verified.</p>
            <p>3) Convenient - Versa allows 2-way communication between buyers and sellers.</p>
            <h6>Buyer side optimizations:</h6>
            <p>1) Customizability - Allows custom portfolio creation along with custom pricing and availability.</p>
            <p>2) Digital - 100% digital transactions.</p>
            <p>3) Flexible - Allows multiple listings and cancellation flexibility.</p>
            <br />
            <h4>What makes Versa Different?</h4>
            <p>Pricing Freedom - Versa doesn’t restrict seller pricing.</p>
            <p>Flexible - Allows multiple listings and cancellation flexibility.</p>
            <p>Digital Payments - Versa is a 100% cashless platform which is a huge perk compared to competitors.</p>
            <hr />
        </Container>
    )
}

export default HowItWorksPage
