import React from 'react'
import clsx from 'clsx';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  AvailabilityCalendar,
  AvailabilityEvent,
  MsSinceMidnightRange,
  Booking,
  Range,
  CalendarThemeProp,
} from 'react-availability-calendar';
import moment from 'moment';
 
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import 'bootstrap/dist/css/bootstrap.min.css';
  import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Container,
    Divider,
    FormControl,
    Grid,
    GridList,
    GridListTile,
    Input,
    InputLabel,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
    makeStyles
  } from '@material-ui/core';
  import avatar1 from '../../assets/images/avatars/avatar1.jpg';
  import avatar2 from '../../assets/images/avatars/avatar2.jpg';
  import avatar3 from '../../assets/images/avatars/avatar3.jpg';
  import SingleReview from '../reusable-components/SingleReview'
  import SingleListing from '../reusable-components/SingleListing'
  import listingpic from '../../assets/images/stock-photos/stock-2.jpg';
  import ProfileSnippet from '../reusable-components/ProfileSnippet'
  import {useHistory, useLocation} from 'react-router-dom'
  import StarRatings from 'react-star-ratings';
  import { useAuth } from "../../hooks/useAuth";
  import { DatePicker } from "@material-ui/pickers";
  import MomentUtils from '@date-io/moment';
  import { MuiPickersUtilsProvider } from '@material-ui/pickers';
  import { useForm, Controller } from "react-hook-form";
  // import CalendarTemplate from '../calendarTemplate/Calendar';
  import GoogleMapReact from 'google-map-react';
  import L from 'leaflet';
  import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
  import 'leaflet/dist/leaflet.css';
  import icon from 'leaflet/dist/images/marker-icon.png';
  import iconShadow from 'leaflet/dist/images/marker-shadow.png';
  import MuiAlert from '@material-ui/lab/Alert';
  import Snackbar from '@material-ui/core/Snackbar';
  import { loadStripe } from "@stripe/stripe-js";
  import { uri } from '../../config/uri';
  import '../searchResult/SearchResult.css'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
  });
  L.Marker.prototype.options.icon = DefaultIcon;
  const AnyReactComponent = ({ text }) => <div>{text}</div>;
  
  const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(3),
      flexDirection: "column"
    },
    avatar: {
      height: 100,
      width: 100
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 250,
      maxWidth: 300,
    },
    title: {
      fontWeight: 300,
      marginTop: '10px',
    },
    location: {
      marginTop: '10px',
      fontSize: '13px',
      color: 'gray',
    }
  }));

  const msInHour = 60 * 60 * 1000;
  const now = new Date();
  const providerTimeZone = 'America/New_York';

  const states = [
    {
      value: 'AL',
      label: 'Alabama'
    },
    {
      value: 'NY',
      label: 'New York'
    },
    {
      value: 'PA',
      label: 'Pennsylvania'
    }
  ];

/**
 * Component comment
 *
 * @component
 */
function ListingPage({ match }) {
    // console.log(match.params.id)
    const classes = useStyles();
    const { user, logout } = useAuth();
    const [AvailabileDateTimes, setAvailabileDateTimes] = useState([]);
    const minDate = new Date(new Date().getTime());
    const [maxDate, setMaxDate] = useState();
    const [listing, setListing] = useState();
    const [msg, setMsg] = useState("");
    const history = useHistory();
    const [seller, setSeller] = useState({});
    const [sellerProfile, setSellerProfile] = useState({});
    const [sellerStats, setSellerStats] = useState({});
    const [listingImgs, setListingImgs] = useState([]);
    const [rating, setRating] = useState();
    const [categories, setCategories] = useState([]);
    const sample_Carousal_images = [{
      original: 'https://picsum.photos/id/1018/1000/600/',
      thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1015/1000/600/',
      thumbnail: 'https://picsum.photos/id/1015/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1019/1000/600/',
      thumbnail: 'https://picsum.photos/id/1019/250/150/',
    },];

    // setListingImgs([{
    //   original: 'https://picsum.photos/id/1018/1000/600/',
    //   thumbnail: 'https://picsum.photos/id/1018/250/150/',
    // }]);

    const review = {
        listingid: '',
        userid: '',
        review: 'Excellent photos. Jane came highly recommended and she lived up to them. Cannot recommend enough. Will be back. This was a great experience, especially considering the low price.',
        rating: 3.5,
        date: '02/02/2020'
    };

    const [position, setMapPosition] = useState([0,0]);
    const zoom = 11;
    const [listingLocation, setListingLocation] = useState({});
    function getListingLocation() {
      // TODO: GET REQUEST TO BACKEND FOR LISTING LOCATION INFO
      setMapPosition([39.95357309331861, -75.16135899055433]);
      return {
          neighborhood: "DummyNeighborhood",
          city: "Philadelphia",
          state: "PA",
          zipcode: 10025,
          country: "USA",
          lat: 39.95357309331861,
          lng: -75.16135899055433
      };
    }
    const [reviews, setReviews] = useState([]);
    function getListingReviews() {
      // TODO: GET REQUEST TO BACKEND FOR LISTING'S REVIEWS
      return [review, review];
    }
    const [galleryImages, setGalleryImages] = useState([]);
    function getGalleryImages() {
      // TODO: GET REQUEST TO BACKEND FOR LISTING'S IMAGES
      return tileData;
    }
  
    // GET LISTING INFO ONLOAD
    useEffect(() => {
      const fetchListing = async () => {
          console.log(match.params.id)
          const res = await axios.get(`${uri}/listing/`, { params: { id: match.params.id }})
          console.log("trynna set the listing data")
          console.log(res.data)
          setListing(res.data)

          let listingImages1 = []
          res.data.photo_urls.map((photo) => {
            let cur = {original: photo.url, thumbnail: photo.url}
            listingImages1.push(cur);
          });
          setListingImgs(listingImages1)

          let numReviews = 0;
          let totalRating = 0;
          res.data.reviews.forEach(review => {
            numReviews += 1;
            totalRating += review.rating;
          })
          if (numReviews != 0) {
            setRating(totalRating / numReviews);
          }
      }

      const fetchSellerProfile= async() => {
        console.log("Fetching seller details")
        const res = await axios.get(`${uri}/listing/`, { params: { id: match.params.id }})
        var userid = res.data.user_id;
        const res2 = await axios.get(`${uri}/user/`, { params: { user_id: userid }})

        const res3 = await axios.get(`${uri}/num-listings/`, { params: { user_id: userid }})
        const res4 = await axios.get(`${uri}/num-reviews/`, { params: { user_receive_id: userid }})
        // console.log("SELLER INFO")
        // console.log(res2.data)
        setSellerProfile(res2.data)
        let sellerDataForDisplay = {
          avatar: res2.data.avatar,
          email: res2.data.email,
          first_name: res2.data.first_name,
          last_name: res2.data.last_name,
          bio: 'Not Available',
          id: res.data.id,
          userid: res.data.user_id
        }
        let sellerstats = {
          numListings: res3.data,
          numReviews: res4.data
        }
        setSeller(sellerDataForDisplay)
        setSellerStats(sellerstats);
      }

      const parseDateTimesFromServer = async () => {
        const sellerListing = await axios.get(`${uri}/listing/`, { params: { id: match.params.id }})
        var userid = null;
        if(sellerListing) {
          userid = sellerListing.data.user_id;
        }
        const res = await axios.get(`${uri}/availabilities/`, { params: { user_id: userid }})
        
        // console.log('hello from fetchAvailableDateTimes')
        // console.log(res.data)
        var start_timestamps = new Set()
        var timestamps = []
        // const timestampset = new Set([])
        for (var i=0; i<res.data.length;i++){
          var start = new Date(res.data[i].start_timestamp*1000)
          var end = new Date(res.data[i].end_timestamp*1000)
          var curPair = [start,end]
          // console.log(curPair)
          if (!start_timestamps.has(res.data[i].start_timestamp)){
              timestamps.push(curPair)
              start_timestamps.add(res.data[i].start_timestamp)
          }
          else{
            // console.log('dupl pair found')
          }
        }
        // console.log(timestamps)
        var curMaxDate = new Date(Math.max.apply(null, Array.from(start_timestamps))*1000);
        setMaxDate(curMaxDate)
        setAvailabileDateTimes(timestamps)
      }

      const fetchReviews = async () => {
        const res = await axios.get(`${uri}/reviews/:listingId`, { params: { listing_id: match.params.id }})
        setReviews(res.data) 
      }
      
      parseDateTimesFromServer();
      fetchListing();
      fetchReviews();
      fetchSellerProfile();
      
      setListingLocation(getListingLocation());
      setGalleryImages(getGalleryImages());
      const query = new URLSearchParams(window.location.search);
      if (query.get("success")) {
            setMsg("success");
      }

      if (query.get("canceled")) {
            setMsg("canceled");
      }

      const fetchCategories = async () => {
        const res = await axios.get(`${uri}/categories/`)
        setCategories(res.data);
      }
      fetchCategories();
    }, []);

    function capitalizeFirstChar(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleClick = async (e) =>{
      console.log(chosenTimeSlot);
      e.preventDefault();
      const stripe = await stripePromise;
      //const response = await fetch("/create-checkout-session", {
          //method: "POST",
      //});
      const data = {
        price: listing.price*100,
        listing_name: listing.title,
        chosenTimeSlot: chosenTimeSlot[0].toGMTString() + "-" +chosenTimeSlot[1].toGMTString(),
        sellerId: sellerProfile.id,
        listingId: listing.id,
        userId: user.id
      };
      console.log(data);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URI}`+'/create-checkout-session',
                      data);
      const session = await response.data;
      //console.log(session.id);
      // When the customer clicks on the button, redirect them to Checkout.
      const result = await stripe.redirectToCheckout({
          sessionId: session.id,
      });
      
      if (result.error) {
          console.log(result.error);
          setMsg("error");
      }

      // change it to the new listing path
      history.push("/listing");
  };


    // calendar
    const [date, changeDate] = useState(minDate);
    const [chosenTimeSlot, setChosenTimeSlot] = useState([]);

    const handleChange = (event) => {
      setChosenTimeSlot(event.target.value);
    };
  
    const { register, errors, handleSubmit, control } = useForm();
    function onSubmit() {
      // TODO: POST NEW BOOKING DATA TO BACKEND
      console.log(chosenTimeSlot);
      console.log(date);
    }

    const [newReviewRating, setNewReviewRating] = useState(0);
    async function onReviewSubmit(data) {
      if (user.id == listing.user_id) {
        alert('You cannot submit review for your own listing');
        return;
      }
      const res = await axios.post(`${uri}/review/`, {
        listing_id: listing.id,
        rating: newReviewRating,
        comment: data.newreview,
        user_submit_id: user.id,
        user_receive_id: listing.user_id,
      })
      window.location.reload();
    }

    if (!listing) return null

    return (
      <Container maxWidth="lg" className={classes.root}>
          <Card className="card-box mb-4">
            {/* <div className="card-header">
              <div className="font-size-lg d-block text-truncate">
                {listing.title}
              </div>
            </div> */}
            <CardContent className="p-3">
              <h3 className={classes.title}>{listing.title}</h3>
              <div className="searchResult_stars">
                    <StarRatings
                        rating={rating}
                        starRatedColor="#ffc600"
                        starDimension="15px"
                        starSpacing="2px"
                        name='rating'
                    />
                    {rating && <p>({rating}) &#8226; {listing.location.address_line} {listing.location.city}, {listing.location.state} {listing.location.zip_code}</p>}
                  </div>
              <br />
              <Container style={{}}>
                <ImageGallery items={listingImgs} />
                {/* <ImageGallery items={listingImages} /> */}
                {/* <GridList cellHeight={160} className={classes.gridList} cols={6}>
                  {listing.photo_urls.map((photo) => (
                    <GridListTile key={photo.id} cols={1}>
                      <img src={photo.url} alt={photo.url} />
                    </GridListTile>
                  ))}
                </GridList> */}
              </Container>


              <br />
              <Divider />
              <br />
              <Grid container spacing={1}>
                <Grid item xs={12}>
                <h3 className={classes.title}>{listing.title}</h3>
                </Grid>
                <Grid item xs={2}>
                    <p>Price: <strong>${listing.price}/hour</strong></p>
                    <p>Category:
                    {categories.map((option) => (
                      (listing.category > 0 && categories.length > 0 && listing.category == option.value && <strong key={option.label}> {capitalizeFirstChar(option.label)} </strong>)
                    ))}
                    </p>
                </Grid>
                <Grid item xs={10}>
                    <p>{listing.description}</p>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                    <h5>Availability</h5>
                    <p>Please select an available day and time.</p>
                </Grid>
                
                {/* <Grid item xs={12}>
                  <div style={{ width: 350 }}>
                    <AvailabilityCalendar
                      bookings={bookings}
                      providerTimeZone={providerTimeZone}
                      moment={moment}
                      initialDate={now}
                      onAvailabilitySelected={onAvailabilitySelected}
                      onCalRangeChange={onChangedCalRange}
                      blockOutPeriods={blockOutPeriods}
                    />
                  </div>
                </Grid> */}

                <Grid item xs={12}>
                  <form className={classes.form} noValidate onSubmit={handleClick}>
                    <Grid container>
                      <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          autoOk
                          orientation="landscape"
                          minDate={minDate}
                          maxDate={maxDate}
                          variant="static"
                          openTo="date"
                          value={date}
                          onChange={changeDate}
                        />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={6}>
                        <div>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-mutiple-checkbox-label">Time Slot</InputLabel>
                          <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            value={chosenTimeSlot}
                            onChange={handleChange}
                          >
                            {/* TIME SLOT DROPDOWN MENU
                                Uses Moment functions to only show the time slot in the dropdown if it matches the selected day
                            */}
                            {AvailabileDateTimes.map((value) => (
                              ((moment(value[0]).isBetween(moment(date).startOf('day'), moment(date).endOf('day'))) && 
                              <MenuItem key={value} value={value}>
                                {moment(value[0]).format('hh:mm a') + " - " + moment(value[1]).format('hh:mm a')}
                              </MenuItem>)
                            ))}
                          </Select>
                        </FormControl>
                        <br /><br />
                        <p><b>Booking Date & Time: </b></p>
                        {chosenTimeSlot[0] && <p>{moment(chosenTimeSlot[0]).format('dddd, MMMM Do YYYY, hh:mm a') + " - " + moment(chosenTimeSlot[1]).format('hh:mm a')}</p>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={!chosenTimeSlot[0]}
                            className={classes.submit}>
                              Reserve Booking
                        </Button>
                          </div>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>


                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <h5>Seller Information</h5>
                </Grid>
                <Grid item xs={12}>
                  {user && user.id == listing.user_id && <ProfileSnippet user={seller} edit={true} stats={sellerStats} />}
                  {user && user.id != listing.user_id && <ProfileSnippet user={seller} edit={false} stats={sellerStats} />}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <h5>Reviews</h5>
                  <div className="searchResult_stars">
                    <StarRatings
                        rating={rating}
                        starRatedColor="#ffc600"
                        starDimension="15px"
                        starSpacing="2px"
                        name='rating'
                    />
                    {rating && <p>({rating})</p>}
                  </div>
                </Grid>
                {reviews.map((review) => (
                  <Grid key={review.id} item xs={6} sm={6} md={6} lg={6}>
                    <SingleReview showCustomer={true} review={review} />
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <form className={classes.form} noValidate onSubmit={handleSubmit(onReviewSubmit)}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <h6>Leave a review</h6>
                      </Grid>
                      <Grid item xs={2}>
                        <StarRatings
                          rating={newReviewRating}
                          starRatedColor="#ffc600"
                          starDimension="20px"
                          starSpacing="2px"
                          name='rating'
                          changeRating={setNewReviewRating}
                          disabled={user && user.id == listing.user_id}
                          />
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          variant="outlined"
                          // margin="normal"
                          fullWidth
                          id="newreview"
                          placeholder="Comment . . ."
                          name="newreview"
                          size="small"
                          inputRef={register({ required: true })}
                          disabled={user && user.id == listing.user_id}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={user && user.id == listing.user_id}>
                              Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
              <br />
              <Divider />
              <br />
              <h5>Location</h5>
              <p>{listing.location.address_line} {listing.location.city}, {listing.location.state} {listing.location.zip_code}</p>
              {/* <div className="w-100" style={{ height: '350px' }}>
                <GoogleMapReact defaultCenter={center} defaultZoom={zoom}>
                  <AnyReactComponent lat={59.955413} lng={30.337844} text="My Marker" />
                </GoogleMapReact>
              </div> */}
              <Map center={position} zoom={zoom}>
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position}>
                  <Popup>
                    {listing.title}
                  </Popup>
                </Marker>
              </Map>
            </CardContent>
          </Card>
          <Snackbar open={msg == "success"} autoHideDuration={6000} 
            onClose={() => setMsg("")}>
                <Alert severity={msg}>
                    Payment Successful
                </Alert>
            </Snackbar>
      </Container>
    )
}

const tileData = [
  {
    img: 'https://images.unsplash.com/photo-1603425013520-e0b30e6e37dc', title: 'studio', cols: 2,
  },{
    img: 'https://images.unsplash.com/photo-1598086849618-96e025dab83c', title: 'person', cols: 1,
  },{
    img: 'https://images.unsplash.com/photo-1488926756850-a13b25e2f415', title: 'camera', cols: 1,
  },{
    img: 'https://images.unsplash.com/photo-1586732711591-12c04655338f', title: 'studio2', cols: 2,
  },{
    img: 'https://images.unsplash.com/photo-1597703182612-964f151b555d', title: 'person2', cols: 1,
  },{
    img: 'https://images.unsplash.com/photo-1471341971476-ae15ff5dd4ea', title: 'studio3', cols: 2,
  },{
    img: 'https://images.unsplash.com/photo-1530832227153-9276cf1d19af', title: 'camera2', cols: 1,
  },{
    img: 'https://images.unsplash.com/photo-1459184070881-58235578f004', title: 'camera3', cols: 2,
  },
];

export default ListingPage;
