import React from 'react'
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3)
    },
}));
/**
 * Terms & Conditions page displays information the company's terms & conditions
 *
 * @component
 */
function TermsAndConditionsPage() {
    const classes = useStyles();
    // const history = useHistory();

    return (
        <Container component="main" maxWidth="md" className = {classes.root}>
            <h3>Terms & Conditions</h3>
            <hr />
            <p>
            Agreement between User and https://versaup.com/. Welcome to https://versaup.com/. The https://versaup.com/ website (the "Site") is comprised of various web pages operated by Vice Versa Technologies, Inc. ("Versa"). https://versaup.com/ is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of https://versaup.com/ constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference. https://versaup.com/ is a Services Marketplace Site. Versa is a marketplace platform to discover & book creative services from a community of independent professionals who set their own hourly rates & availability. Sellers list their services & confirm or deny requests from buyers. Versa takes a transaction fee per transaction.
            </p>
            <hr />
        </Container>
    )
}

export default TermsAndConditionsPage
