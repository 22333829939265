import React from 'react'
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3)
    },
}));
/**
 * Privacy Policy page displays information the company's privacy policy
 *
 * @component
 */
function PrivacyPolicyPage() {
    const classes = useStyles();
    // const history = useHistory();

    return (
        <Container component="main" maxWidth="md" className = {classes.root}>
            <h3>Privacy Policy</h3>
            <hr />
            <h6>Privacy</h6>
            <p>Your use of https://versaup.com/ is subject to Versa's Privacy Policy. Please review our Privacy Policy, which also governs the Site and informs users of our data collection practices.</p>
            <h6>Electronic Communications</h6>
            <p>Visiting https://versaup.com/ or sending emails to Versa constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.</p>
            <h6>Your Account</h6>
            <p>If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that Versa is not responsible for third party access to your account that results from theft or misappropriation of your account. Versa and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit content in our sole discretion.</p>
            <h6>Children Under Thirteen</h6>
            <p>Versa collects personally identifiable information from children under the age of thirteen. Versa collects this information for the following reason(s): Currently there are no provisions for age limits on Versa as this is our v1 prototype, going forward we will limit usage for minors. It is possible for children under the age of 13 to use their email, Facebook, or Gmail to simply create a profile. However, all users are currently vetted & verified by the team. Personal data of minors will not be used, sold, or leased by Vice Versa Technologies, Inc. ("Versa") or any 3rd parties for any reason. We provide information about our personal data practices for children on our home page and wherever we knowingly collect personal data from children on our Web site. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website. If you are a parent and you have questions regarding our data collection practices, please contact us using the information provided at the end of this Agreement. If you are under 18, you may use https://versaup.com/ only with permission of a parent or guardian.</p>
            <hr />
        </Container>
    )
}

export default PrivacyPolicyPage
