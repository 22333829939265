import React from 'react'
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    Divider,
    Grid,
    TextField,
    Input,
    GridList,
    GridListTile,
    GridListTileBar,
    IconButton,
    makeStyles
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { useState, useEffect } from 'react'

import axios from 'axios';
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from 'react-router-dom'

import { uri } from '../../config/uri';
import { useAuth } from "../../hooks/useAuth";
import Layers from '@material-ui/icons/Layers'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3)
    },
    gridList: {
        width: 'auto',
        height: 'auto',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
}));


/**
 * AddListing component allows users to create a new listing.
 *
 * @component
 */
function AddListingPage({ match }) {
    const [categories, setCategories] = useState([])
    const [photoUrls, setPhotoUrls] = useState([])

    const classes = useStyles();
    const { user } = useAuth();
    const history = useHistory();

    const { register, errors, handleSubmit, control } = useForm();

    /**
     * Saves the new listing in the backend.
     *
     * @function
     * @param {Object} data - New listing data
     */
    async function onSubmitData(data) {
        if (photoUrls.length == 0) {
            alert('Please add photos');
            return;
        }
        try {
            const res = await axios.post(`${uri}/listing/`, {
                "title": data.title,
                "description": data.description,
                "price": data.price,
                "category": parseInt(data.category),
                "user_id": user.id,
                "location": {
                  "address_line": data.addressLine,
                  "city": data.city,
                  "state": data.state,
                  "zip_code": data.zipcode
                },
                "photo_urls": photoUrls
            });

            history.push('/mylistings');
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Uploads selected photos to the backend.
     *
     * @function
     * @param {Object} files - Photos to be uploaded
     */
    async function addPhotoFiles(files) {
        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append("photo_files", files[i], files[i].name)
        }

        const url = `${uri}/uploadphotos/`

        const s3PhotoUrls = await axios.post(`${uri}/uploadphotos/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        setPhotoUrls(oldPhotoUrls => [...oldPhotoUrls, ...s3PhotoUrls.data])
    }

    /**
     * Removes a selected photo from the backend.
     *
     * @function
     * @param {string} url - Photo to be removed
     */
    function removePhoto(url) {
        axios.delete(`${uri}/deletephoto`, {
            params: {
                url: url
            }
        })

        setPhotoUrls(photoUrls.filter(item => item.url !== url))
    }

    /**
     * Capitalizes the first character of a given string.
     *
     * @function
     * @param {string} string - String to be capitalized
     */
    function capitalizeFirstChar(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);

    }

    useEffect(() => {
        /**
         * Retrieves list of categories from the backend.
         *
         * @function
         * @memberOf AddListingPage
         */
        const fetchCategories = async() => {
            const res = await axios.get(`${uri}/categories`)
            setCategories(res.data)
        }
        fetchCategories();
    }, []);

    return (
        <div className = {classes.root}>
            <h3 style={{textAlign:"center"}}><Layers className='largeIcon'/> My Listings</h3>
            <hr></hr>

            <Container className={classes.root}>
                <Grid container justify="space-evenly">
                    <Grid item lg={12} md={12} xs={12}>
                        <form autoComplete="off" onSubmit={handleSubmit(onSubmitData)}>
                            <Card>
                                <CardHeader
                                    subheader="Enter your listing details here."
                                    title="Add Listing"
                                />
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Listing Title"
                                                name="title"
                                                type="string"
                                                required
                                                variant="outlined"
                                                inputRef={register({ required: true })}
                                            />
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                fullWidth
                                                id="outlined-multiline-static"
                                                label="Description"
                                                name="description"
                                                multiline="false"
                                                rows="4"
                                                variant="outlined"
                                                required
                                                inputRef={register({ required: true })}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={6}>Pricing</Grid>
                                        <Grid item md={6} xs={6}>Category</Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Price Per Hour"
                                                name="price"
                                                required
                                                type="number"
                                                variant="outlined"
                                                inputRef={register({ required: true })}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Category"
                                                name="category"
                                                required
                                                select
                                                SelectProps={{ native: true }}
                                                variant="outlined"
                                                inputRef={register({ required: true })}
                                                InputLabelProps={{ shrink: true }}
                                            >
                                                {categories.map((option) => (
                                                    <option key={option.label} value={option.value}>
                                                        {capitalizeFirstChar(option.label)}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item md={12} xs={12}>Location</Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Address"
                                                name="addressLine"
                                                required
                                                variant="outlined"
                                                inputRef={register({ required: true })}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="City"
                                                name="city"
                                                required
                                                variant="outlined"
                                                inputRef={register({ required: true })}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="State"
                                                name="state"
                                                required
                                                variant="outlined"
                                                inputRef={register({ required: true })}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="ZipCode"
                                                name="zipcode"
                                                required
                                                type="number"
                                                variant="outlined"
                                                inputRef={register({ required: true })}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} xs={12}>
                                            <GridList cellHeight={180} className={classes.gridList}>
                                                {photoUrls.map((item) => (
                                                    <GridListTile key={item.url}>
                                                        <img src={item.url} />
                                                        <GridListTileBar
                                                            actionIcon={
                                                                <IconButton className={classes.icon} onClick={() => removePhoto(item.url)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            }
                                                        />
                                                    </GridListTile>
                                                ))}
                                            </GridList>
                                            <input
                                                accept="image/*"
                                                className={classes.input}
                                                style={{ display: 'none' }}
                                                id="raised-button-file"
                                                multiple
                                                type="file"
                                                onChange={(e) => addPhotoFiles(e.target.files)}
                                            />
                                            <label htmlFor="raised-button-file">
                                                <Button variant="contained" color="secondary" component="span" className={classes.button}>
                                                    Upload Photos
                                            </Button>
                                            </label>
                                            </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider />
                                <Box display="flex" justifyContent="flex-end" p={2}>
                                    <Button type="submit" color="primary" variant="contained">
                                        Save Listing
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
export default AddListingPage
