import React from 'react'
import { uri } from '../../config/uri';
import axios from 'axios';
import { useState, useEffect } from 'react';

/**
 * UserHelper
 *
 * @component
 */
function UserHelper(props) {
    const userid = props.userid;
    const [user, setUser] = useState({});

    useEffect(() => {
        const fetchUser = async() => {
            const res = await axios.get(`${uri}/user/`, { params: { user_id: userid }});
            setUser(res.data);
            return(res.data);
        }
        fetchUser();
      }, []);

    return (
        <strong>{user.first_name} {user.last_name}</strong>
    )
}
export default UserHelper;
